import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Layout } from '../layout';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';
import { useLayout } from '../layout/useLayout';

type LinkType = {
  id: number;
  image: string;
  urlImage: IGatsbyImageData | string;
  name: {
    en: string;
    ru: string;
  };
  post: {
    en: string;
    ru: string;
  };
};

type Data = Record<string, { en: string; ru: string }>;
const personal: LinkType[] = [
  {
    id: 1,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'ilya_gocenko',
    name: { en: 'Ilya', ru: 'Илья' },
    post: { en: 'Management/ Strategy', ru: 'Менеджмент/ Стратегия' },
  },
  {
    id: 2,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'andrei_sergienko',
    name: { en: 'Andrei', ru: 'Андрей' },
    post: {
      en: 'Project management',
      ru: 'Управление проектами',
    },
  },
  {
    id: 3,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'anastasia_shmeleva',
    name: { en: 'Anastasia', ru: 'Анастасия' },
    post: { en: 'Paperwork/ Administration', ru: 'Делопроизводство/ Администрирование' },
  },
  {
    id: 4,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'oksana_volkogonova',
    name: { en: 'Oksana', ru: 'Оксана' },
    post: {
      en: 'Accounting/ Financial Management',
      ru: 'Бухгалтерия/ Управление финансами',
    },
  },
  {
    id: 5,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'nadezda_krasnikova',
    name: { en: 'Nadezhda', ru: 'Надежда' },
    post: { en: 'Accounting', ru: 'Бухгалтерия' },
  },
  {
    id: 6,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'rashid_ilyasov',
    name: { en: 'Rashid', ru: 'Рашид' },
    post: {
      en: 'System Administration',
      ru: 'Системное администрирование',
    },
  },
  {
    id: 7,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'alexander_klochkov',
    name: { en: 'Alexander', ru: 'Александр' },
    post: { en: 'Android Development', ru: 'Android разработка' },
  },
  {
    id: 8,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'stanislav_lutsko',
    name: { en: 'Stanislav', ru: 'Станислав' },
    post: {
      en: 'Development C++/ Video analysis',
      ru: 'Разработка С++/ Видеоанализ',
    },
  },
  {
    id: 9,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'rashid_halikov',
    name: { en: 'Rashid', ru: 'Рашид' },
    post: {
      en: 'Architecture/ Back-end development',
      ru: 'Архитектура/ Разработка Back-end',
    },
  },
  {
    id: 10,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'grigory_trohinin',
    name: { en: 'Grigory', ru: 'Григорий' },
    post: { en: 'Front-end development', ru: 'Разработка Front-end' },
  },
  {
    id: 11,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'anna_belousova',
    name: { en: 'Anna', ru: 'Анна' },
    post: {
      en: 'Business Analysis/ Project Management',
      ru: 'Бизнес анализ/ Управление проектами',
    },
  },
  {
    id: 12,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'roman_sanzharov',
    name: { en: 'Roman', ru: 'Роман' },
    post: { en: 'Hardware development', ru: 'Hardware разработка' },
  },
  {
    id: 13,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'pavel_belko',
    name: { en: 'Pavel', ru: 'Павел' },
    post: { en: 'Front-end development', ru: 'Разработка Front-end' },
  },
  {
    id: 14,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'nodirzhon_gaziev',
    name: { en: 'ノダー', ru: 'НАДИР' },
    post: {
      en: 'Development back-end',
      ru: 'Разработка Back-end',
    },
  },
  {
    id: 15,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'sergey_kazdorf',
    name: { en: 'Sergey', ru: 'Сергей' },
    post: {
      en: 'Data Science/ AR/ Mobile Applications',
      ru: 'Data Science/ AR/ Мобильные приложения',
    },
  },
  {
    id: 16,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'maria_goncharova',
    name: { en: 'Maria', ru: 'Мария' },
    post: {
      en: 'Data Science/ Project Management',
      ru: 'Data Science/ Управление проектами',
    },
  },
  {
    id: 17,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'genadiy_tsicovkin',
    name: { en: 'Gennady', ru: 'Геннадий' },
    post: {
      en: 'UX/ UI/ Product Management',
      ru: 'UX/ UI/ Product Management',
    },
  },
  {
    id: 18,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'mixail_kirienko',
    name: { en: 'Michael', ru: 'Михаил' },
    post: {
      en: 'Sales/ Project Management',
      ru: 'Продажи/ Управление проектами',
    },
  },
  {
    id: 19,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'aleksandr_kotlayr',
    name: { en: 'Alexander', ru: 'Александр' },
    post: { en: 'DevOps', ru: 'DevOps' },
  },
  {
    id: 20,
    urlImage: 'https://skytracking.ru/static/we/ilya_gocenko.png',
    image: 'vladimir_martynyuk',
    name: { en: 'Vladimir', ru: 'Владимир' },
    post: { en: 'Front-end development', ru: 'Разработка Front-end' },
  },
];

const siteTitle = {
  title: {
    en: 'Team',
    ru: 'Команда Скайтрэк',
  },
};

const siteData = {
  description: {
    en:
      'A team of professionals in software development, video analysis and artificial intelligence',
    ru:
      'Команда профессионалов в области разработки программного обеспечения, видеоанализа и искусственного интеллекта',
  },
};

const subText = [
  {
    en:
      'are constantly searching for extraordinary solutions, combining diverse points of view, methods and theories to create harmony. And it is this desire to find, try and learn that lies at the heart of our unique identity and forms the base of our excellence.',
    ru:
      'Постоянно находимся в поиске, ищем неординарные решения, соединяем разнородные точки зрения, методы, теории, чтобы создать гармонию. И это желание найти, жажда попробовать и узнать — суть нашей уникальности, база для высоких достижений.',
  },
  {
    en: 'are constantly searching for extraordinary solutions,',
    ru: 'Постоянно находимся в поиске, ищем неординарные решения,',
  },
  {
    en:
      'combining diverse points of view, methods and theories to create harmony.',
    ru:
      'соединяем разнородные точки зрения, методы, теории, чтобы создать гармонию.',
  },
  {
    en:
      'And it is this desire to find, try and learn that lies at the heart of our unique identity and forms the base of our excellence.',
    ru:
      'И это желание найти, жажда попробовать и узнать — суть нашей уникальности, база для высоких достижений.',
  },
];

const data: Data = {
  title: { en: 'We', ru: 'Мы' },
  titleMobile: { en: 'We', ru: 'Мы' },
};

const SubTitle = () => {
  const { lang } = useLayout();
  return (
    <>
      <WrapperSubTitle>
        <div style={{ textIndent: '11em' }}>{subText[0][lang]}</div>
      </WrapperSubTitle>
      <MobileWrapperSubTitle>
        <div>{subText[1][lang]}</div>
        <div style={{ textIndent: '2.5em', marginBottom: '5vw' }}>
          {subText[2][lang]}
        </div>
        <div style={{ textIndent: '2.5em' }}>{subText[3][lang]}</div>
      </MobileWrapperSubTitle>
    </>
  );
};

function shuffle(array: Array<Record<string, any>>) {
  const arr = array.slice(0);
  for (let i = arr.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

const addMargin = <T,>(xs: Array<Array<T>>) => {
  return xs.map((x) => {
    const s = () => {
      return Math.random() * 100 + '%';
    };

    const getStyle = (): React.CSSProperties => {
      const x = s();
      return {
        left: x,
        transform: `translateX(-${x})`,
      };
    };
    const left = {
      ...x[0],
      style: getStyle(),
    };
    const right = {
      ...x[1],
      style: getStyle(),
    };
    return [left, right];
  });
};

const groupByTotal = (n: number) => <A,>(as: A[]) => {
  const lengthWe = Math.ceil(as.length / n);
  return new Array(lengthWe).fill(n).map((x, i) => as.slice(i * n, x + i * n));
};

const We = () => {
  const [render, setRender] = useState(false);
  const images = useStaticQuery(graphql`
    {
      allImageSharp {
        edges {
          node {
            gatsbyImageData
          }
        }
      }
    }
  `);

  const tempPersonal = personal.map((item) => {
    const image = images.allImageSharp.edges.find((img: any) =>
      img.node.gatsbyImageData.images.fallback.src.includes(item.image),
    );
    return {
      ...item,
      urlImage: image?.node.gatsbyImageData,
    };
  });

  const grouped = groupByTotal(2);
  const concatMargin = addMargin(grouped(shuffle(tempPersonal)));

  useEffect(() => {
    setRender(true);
  }, []);

  return (
    <Layout
      titlePage={data.title}
      titlePageMobile={data.titleMobile}
      title={siteTitle.title}
      description={siteData.description}
      rendererSubTitle={() => <SubTitle />}
    >
      {({ lang }: { lang: 'en' | 'ru' }) => (
        <>
          {render && (
            <Container>
              {concatMargin.map((item, index) => {
                return (
                  <Content key={index}>
                    {item.map((x) => {
                      return (
                        <BlockWrapper key={x.id}>
                          <Block style={x.style}>
                            <WrapperPhoto>
                              <GatsbyImage
                                image={x.urlImage && getImage(x.urlImage)}
                                alt={x.image}
                                title={x.post[lang]}
                              />
                            </WrapperPhoto>
                            <Name>{x.name[lang]}</Name>
                            <Position>{x.post[lang]}</Position>
                          </Block>
                        </BlockWrapper>
                      );
                    })}
                  </Content>
                );
              })}
            </Container>
          )}
        </>
      )}
    </Layout>
  );
};

export default We;

const Block = styled.div`
  position: relative;
  display: inline-block;
`;

const BlockWrapper = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.table_smallest}) {
    padding-left: max(1.4vw, env(safe-area-inset-left));
    padding-right: max(1.4vw, env(safe-area-inset-right));
  }
`;

const Container = styled.div``;

const Content = styled.div`
  padding: 4.17vw 1.39vw 3.47vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3vw;
  grid-auto-flow: column;
  border-bottom: 0.21vw solid #071013;
  :last-child {
    border-bottom: none;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    grid-template-columns: 1fr;
    grid-auto-flow: inherit;
    grid-gap: 13vw;
    padding: 17.67vw 4.65vw 13.26vw;
  }
`;

const WrapperPhoto = styled.div`
  width: 15vw;
  height: 15vw;
  background: transparent;
  img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    width: 42.79vw;
    height: 42.79vw;
  }
`;

const Name = styled.div`
  font-family: 'Atyp Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.6vw;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #071013;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 3.72vw;
    line-height: 5.58vw;
  }
`;

const Position = styled.div`
  font-family: 'Atyp Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.6vw;
  color: #071013;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 4.19vw;
    line-height: 5.58vw;
    width: 35vw;
  }
`;

const WrapperSubTitle = styled.div`
  width: 90.63vw;
  margin-top: 1.39vw;
  margin-bottom: 3.47vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: none;
  }
`;

const MobileWrapperSubTitle = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 5.4vw;
    line-height: 8vw;
    display: grid;
    margin-top: 8vw;
    margin-bottom: 8vw;
  }
`;
