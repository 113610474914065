exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-clients-tsx": () => import("./../../../src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-creations-tsx": () => import("./../../../src/pages/creations.tsx" /* webpackChunkName: "component---src-pages-creations-tsx" */),
  "component---src-pages-ecosystems-tsx": () => import("./../../../src/pages/ecosystems.tsx" /* webpackChunkName: "component---src-pages-ecosystems-tsx" */),
  "component---src-pages-formalities-tsx": () => import("./../../../src/pages/formalities.tsx" /* webpackChunkName: "component---src-pages-formalities-tsx" */),
  "component---src-pages-geton-tsx": () => import("./../../../src/pages/geton.tsx" /* webpackChunkName: "component---src-pages-geton-tsx" */),
  "component---src-pages-heaven-tsx": () => import("./../../../src/pages/heaven.tsx" /* webpackChunkName: "component---src-pages-heaven-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-madetomeasure-tsx": () => import("./../../../src/pages/madetomeasure.tsx" /* webpackChunkName: "component---src-pages-madetomeasure-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-we-tsx": () => import("./../../../src/pages/we.tsx" /* webpackChunkName: "component---src-pages-we-tsx" */)
}

