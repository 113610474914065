import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Marker } from './marker';

export const Maps: React.FC = (props): JSX.Element => {
  const positions = {
    center: {
      lat: 55.75876559190587,
      lng: 37.598621638362296,
    },
    zoom: 14,
  };
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDXQFUQSUI1fZH9nr4QN1n7RgJItfybIwU' }}
        defaultCenter={positions.center}
        defaultZoom={positions.zoom}
      >
        <Marker
          lat={positions.center.lat}
          lng={positions.center.lng}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
  );
};
