import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Layout } from '../layout';
import { VectorUp, SkolkovoIcon, GroupIcon, FondIcon } from '@icons';

type Data = Record<string, { en: string; ru: string }>;

const data: Data = {
  title: { en: 'Ecosystems', ru: 'Экосистемы' },
  titleMobile: { en: 'Ecosys&shy;tems', ru: 'Экосис&shy;темы' },
  text: {
    en:
      'Expanding within the coordinate system of Russian innovation landscape.',
    ru: 'Мы развиваемся в системе координат инновационной среды России.',
  },
};

const siteTitle = {
  title: {
    en: 'Skolkovo Resident',
    ru: 'Резидент Сколково',
  },
};

const siteData = {
  description: {
    en:
      'Skytrack - Skolkovo resident, winner of the NTI Development competitions',
    ru: 'Скайтрэк - резидент Сколково, победитель конкурсов «Развитие НТИ»',
  },
};

const links = [
  {
    id: 1,
    title: { en: 'Skolkovo', ru: 'Сколково' },
    link: 'https://sk.ru',
    width: '23.26vw',
  },
  {
    id: 2,
    title: {
      en: 'National Technological Initiative',
      ru: 'Национальная технологическая инициатива',
    },
    link: 'https://nti2035.ru',
    width: '39.72vw',
  },
  {
    id: 3,
    title: {
      en: 'Foundation for Assistance to Small Innovative Enterprises',
      ru: 'Фонд содействия инновациям',
    },
    link: 'https://fasie.ru',
    width: '36.11vw',
  },
];

const mobileText = [
  {
    en: 'We are developing ',
    ru: 'Мы развиваемся в системе',
  },
  {
    en: 'in the coordinate system of the innovation environment in',
    ru: 'координат инновационной среды',
  },
  {
    en: 'Russia.',
    ru: 'России.',
  },
];

const Ecosystems = () => {
  const [id, setId] = useState<null | number>(null);

  return (
    <Layout
      titlePage={data.title}
      titlePageMobile={data.titleMobile}
      title={siteTitle.title}
      description={siteData.description}
      styleTitle={{ marginBottom: '3.47vw', borderBottom: 'none' }}
    >
      {({ lang }: { lang: 'en' | 'ru' }) => (
        <Container>
          <div>
            <TitleText>{data.text[lang]}</TitleText>
            <MobileTitle>
              <div style={{ textAlign: 'right' }}>{mobileText[0][lang]}</div>
              <div>{mobileText[1][lang]}</div>
              <div style={{ textAlign: 'right' }}>{mobileText[2][lang]}</div>
            </MobileTitle>
            {links.map((item) => {
              return (
                <Content style={{ width: item.width }} key={item.id}>
                  <Eclipse overlay={id === item.id} />
                  <Links
                    href={item.link}
                    onMouseEnter={() => setId(item.id)}
                    onMouseLeave={() => setId(null)}
                    target={'_blank'}
                  >
                    <div>{item.title[lang]}</div>
                    <WrapperVector>
                      <VectorUp />
                    </WrapperVector>
                  </Links>
                </Content>
              );
            })}
          </div>
          <Organizations>
            <div>
              <a href="https://sk.ru" target={'_blank'}>
                <Element>
                  <WrapperSkolkovo>
                    <SkolkovoIcon />
                  </WrapperSkolkovo>
                </Element>
              </a>
              <a href="https://nti2035.ru" target={'_blank'}>
                <Element>
                  <WrapperGroup>
                    <GroupIcon />
                  </WrapperGroup>
                </Element>
              </a>
            </div>
            <div style={{ display: 'grid', alignItems: 'end' }}>
              <a href="https://fasie.ru" target={'_blank'}>
                <Element>
                  <WrapperFond>
                    <FondIcon />
                  </WrapperFond>
                </Element>
              </a>
            </div>
          </Organizations>
        </Container>
      )}
    </Layout>
  );
};

export default Ecosystems;

const Container = styled.div`
  padding: 4.86vw 1.39vw 0;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.table_smallest}) {
    padding-left: max(1.4vw, env(safe-area-inset-left));
    padding-right: max(1.4vw, env(safe-area-inset-right));
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: grid;
    padding: 15.63vw 3.13vw 0;
    justify-content: center;
  }
`;

const TitleText = styled.div`
  margin-bottom: 8.26vw;
  font-family: 'Atyp Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.78vw;
  line-height: 3.19vw;
  text-transform: uppercase;
  color: #000000;
  text-align: justify;
  margin-right: 1.39vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: none;
  }
`;

const Organizations = styled.div`
  border: 0.21vw solid #071013;
  box-sizing: border-box;
  border-radius: 10.76vw;
  display: flex;
  margin-bottom: 10.42vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    border: 0.63vw solid #071013;
    border-radius: 26.76vw;
  }
`;

const Element = styled.div`
  width: 19.86vw;
  height: 19.86vw;
  background: #ffffff;
  border: 0.21vw solid #071013;
  box-sizing: border-box;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    background: #baff49;
    width: 46.88vw;
    height: 46.88vw;
    border-radius: 23.44vw;
    border: 0.63vw solid #071013;
  }
`;

const Links = styled.a`
  font-family: 'Atyp Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.6vw;
  border-bottom: 0.21vw solid #071013;
  color: #071013;
  margin-bottom: 2.64vw;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: end;
  position: relative;
  z-index: 10;
`;

const Eclipse = styled.div<{ overlay: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: ${({ overlay }) => (overlay ? '#BAFF49' : 'transparent')};
  filter: blur(${({ overlay }) => (overlay ? '1.18vw' : '0')});
  transform: matrix(-1, -0.03, -0.03, 1, 0, 0);
  @media (hover: none) {
    background: none;
    filter: none;
  }
`;

const Content = styled.div`
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  cursor: pointer;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: none;
  }
`;

const WrapperSkolkovo = styled.div`
  width: 5.21vw;
  height: 3.89vw;
  margin-left: 1vw;
  & > svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    width: 15.31vw;
    height: 11.56vw;
    margin-left: 2vw;
  }
`;

const WrapperGroup = styled.div`
  width: 7.71vw;
  height: 5.69vw;
  & > svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    width: 25.63vw;
    height: 18.75vw;
  }
`;

const WrapperFond = styled.div`
  width: 7.64vw;
  height: 4.44vw;
  & > svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    width: 22.5vw;
    height: 13.13vw;
    margin-top: 2vw;
  }
`;

const WrapperVector = styled.div`
  margin-bottom: 0.4vw;
  width: 1.67vw;
  height: 1.67vw;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const MobileTitle = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: grid;
    margin-bottom: 18.75vw;
    font-family: 'Atyp Display', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 9.69vw;
    line-height: 12.19vw;
    text-transform: uppercase;
    color: #000000;
  }
`;
