import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Layout } from '../layout';
import { useLayout } from '../layout/useLayout';
import cn from 'classnames';

const siteTitle = {
  title: {
    en: 'Digital solutions and products',
    ru: 'Цифровые решения и продукты',
  },
};

const siteData = {
  description: {
    en:
      'Biometric ACS. Transport monitoring. Intelligent barrier. Automation of business processes. Video analysis. Augmented reality. Digital Platforms and Internet of Things',
    ru:
      'Биометрический СКУД. Мониторинг транспорта. Интеллектуальный шлагбаум. Автоматизация бизнес-процессов. Видеоанализ. Дополненная реальность. Цифровые платформы и Интернет вещей',
  },
};

const subText = [
  {
    en:
      'By anticipating trends, today we create solutions that will become part of everyday life and ordinary digital control tools tomorrow.',
    ru:
      'Предвосхищая тренды, мы сегодня создаем решения, которые завтра станут',
    className: 'one',
  },
  {
    en: '',
    ru:
      'частью повседневной жизни и обычными цифровыми инструментами управления.',
    className: 'two',
  },
  {
    en:
      'Our finished products in the field of transport and stationary security, video monitoring, data analysis and visualization ',
    ru:
      'Наши готовые продукты в области безопасности транспорта и стационарных объектов, видео-мониторинга, анализа и визуализации данных доступны',
    className: 'three',
  },

  {
    en: 'are available',
    ru: 'уже',
    className: 'four',
  },

  {
    en: 'now at HEAVENPLATFORM.COM.',
    ru: 'сейчас на HEAVENPLATFORM.COM.',
    className: 'five',
  },
];

type Links = {
  id: number;
  title: { en: string; ru: string };
  link: string;
  pos: string;
  indent: string;
  mobileStyle?:
    | 'center'
    | 'end'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start';
};

const links: Links[] = [
  {
    id: 1,
    title: {
      en: 'Intelligent driver  video monitoring system',
      ru: 'Интеллектуаль&shy;ная система видео&shy;мониторинга водителя',
    },
    link: 'https://heavenplatform.com/video-monitoring-hcpro',
    pos: 'inherit',
    indent: '0',
    mobileStyle: 'left',
  },
  {
    id: 2,
    title: {
      en: 'Intelligent access control system for transport',
      ru: 'Интеллектуаль&shy;ная система контроля доступа для транспорта',
    },
    link: 'https://heavenplatform.com/numbers-recognition',
    pos: 'auto',
    indent: '5.90vw',
    mobileStyle: 'right',
  },
  {
    id: 3,
    title: {
      en: ' Intelligent access control system for people',
      ru: 'Интел&shy;лектуаль&shy;ная система контроля доступа для людей',
    },
    link: 'https://heavenplatform.com/face-recognition',
    pos: 'inherit',
    indent: '0',
    mobileStyle: 'left',
  },
  {
    id: 4,
    title: {
      en: ' Intelligent data presentation system',
      ru: 'Интеллек&shy;туальная система представления данных',
    },
    link: 'https://heavenplatform.com/data_analysis',
    pos: 'auto',
    indent: '0',
    mobileStyle: 'right',
  },
  {
    id: 5,
    title: {
      en:
        ' Navigation system with  augmented reality, artificial intelligence and DVR.',
      ru: 'AR - ассистент водителя и навигатор для мобильных платформ',
    },
    link: 'https://heavenplatform.com/driver-assistant',
    pos: 'inherit',
    indent: '2.78vw',
    mobileStyle: 'left',
  },
  {
    id: 6,
    title: {
      en: 'Intelligent passenger flow monitoring system',
      ru: 'Интеллек&shy;туальная система мониторинга пассажиропо&shy;тока ',
    },
    link: 'https://heavenplatform.com/passanger-stream',
    pos: 'auto',
    indent: '0',
    mobileStyle: 'right',
  },
  {
    id: 7,
    title: {
      en: 'INTELLIGENT DRIVER AND TRAFFIC MONITORING SYSTEM',
      ru:
        'Интел&shy;лектуаль&shy;ная система мониторинга водителя и дорожной ситуации',
    },
    link: 'https://heavenplatform.com/adas-system',
    pos: 'inherit',
    indent: '0',
    mobileStyle: 'left',
  },
  {
    id: 8,
    title: {
      en: 'Budget planning and reporting management',
      ru: 'Бюджетное планирование и управление отчетностью',
    },
    link: 'https://heavenplatform.com/catalog/data_management',
    pos: 'auto',
    indent: '9.72vw',
    mobileStyle: 'right',
  },
  {
    id: 9,
    title: { en: 'marketplace', ru: 'МАРКЕТПЛЕЙС' },
    link: 'https://skybuy.ru/',
    pos: 'inherit',
    indent: '0',
    mobileStyle: 'left',
  },
];

const data = {
  title: { en: 'Creations', ru: 'Создано' },
  titleMobile: { en: 'Crea&shy;tions', ru: 'Соз&shy;дано' },
};

const SubTitle = () => {
  const { lang } = useLayout();
  return (
    <WrapperSubTitle>
      {subText.map((item, i) => (
        <div key={i} className={cn({ [item.className]: true })}>
          {subText[i][lang]}
        </div>
      ))}
    </WrapperSubTitle>
  );
};

const Creations = () => {
  const [id, setId] = useState<null | number>(null);

  return (
    <Layout
      titlePage={data.title}
      titlePageMobile={data.titleMobile}
      title={siteTitle.title}
      description={siteData.description}
      styleTitleMobile={{ textIndent: '35vw' }}
      rendererSubTitle={() => <SubTitle />}
    >
      {({ lang }: { lang: 'en' | 'ru' }) => (
        <Container>
          {links.map((item) => {
            const active = id === item.id;
            return (
              <div key={item.id}>
                {item.title[lang] && (
                  <WrapperContent>
                    <Eclipse overlay={id === item.id} />
                    <a href={item.link} target={'_blank'}>
                      <ContentText
                        onMouseEnter={() => setId(item.id)}
                        onMouseLeave={() => setId(null)}
                        style={{
                          marginLeft: item.pos,
                          textIndent: item.indent,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: active ? item.link : item.title[lang],
                        }}
                      />
                      <MobileContentText
                        style={{ textAlign: item.mobileStyle }}
                        dangerouslySetInnerHTML={{ __html: item.title[lang] }}
                      />
                    </a>
                  </WrapperContent>
                )}
              </div>
            );
          })}
        </Container>
      )}
    </Layout>
  );
};
export default Creations;

const Container = styled.div`
  padding: 1.63vw 1.4vw 1.41vw;
  width: 100%;
  height: 100%;
  @media screen and (max-width: ${({ theme }) => theme.table_smallest}) {
    padding-left: max(1.4vw, env(safe-area-inset-left));
    padding-right: max(1.4vw, env(safe-area-inset-right));
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    padding: 18.75vw 3.13vw 0;
  }
`;

const WrapperContent = styled.div`
  display: block;
  font-family: 'Atyp Display', sans-serif;
  font-style: normal;
  font-size: 2.8vw;
  line-height: 3.2vw;
  text-align: justify;
  text-transform: uppercase;
  word-spacing: 0.35vw;
  height: 18.5vw;
  position: relative;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 9.69vw;
    line-height: 12.19vw;
    height: 100%;
    margin-bottom: 18.75vw;
  }
`;

const ContentText = styled.div`
  display: block;
  width: 100%;
  max-width: 56.2vw;
  color: #071013;
  cursor: pointer;
  position: relative;
  z-index: 10;
  overflow-wrap: break-word;
  height: 9.6vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: none;
  }
`;

const MobileContentText = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: block;
    color: #071013;
    position: relative;
    cursor: pointer;
    z-index: 10;
    overflow-wrap: break-word;
    height: 100%;
    width: 100%;
  }
`;

const Eclipse = styled.div<{ overlay: boolean }>`
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 10vw;
  top: 50%;
  background: ${({ overlay }) => (overlay ? '#BAFF49' : '#fff')};
  filter: blur(${({ overlay }) => (overlay ? '3vw' : '0')});
  transform: matrix(-1, -0.03, -0.03, 1, 0, 0) translate(0, -50%);
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: none;
  }
  @media (hover: none) {
    background: none;
    filter: none;
  }
`;

const WrapperSubTitle = styled.div`
  width: 71.53vw;
  margin-top: 1.04vw;
  height: 17vw;
  margin-left: auto;
  & > div {
    text-align: right;
  }

  .three {
    margin-top: 1em;
  }

  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    height: 100%;
    width: 100%;
    margin-top: 10vw;
    margin-bottom: 10vw;
    .two,
    .one {
      text-align: left;
    }
    .three {
      margin-top: 1em;
    }
  }
`;
