import React from 'react';
export const MapIcon = () => {
  return (
    <svg
      width="1000"
      height="1000"
      viewBox="0 0 1000 1000"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="500" cy="500" r="500" fill="#BAFF49" />
      <path
        d="M426.239 759.524L141.42 759.27L141.491 679.533L426.31 679.787C433.177 679.794 439.766 676.999 444.626 672.019C449.486 667.039 452.22 660.281 452.227 653.232L452.242 635.889C452.25 629.644 450.117 623.596 446.215 618.806C442.313 614.015 436.893 610.787 430.904 609.689L207.746 569.09C183.768 564.725 162.056 551.827 146.423 532.662C130.79 513.496 122.236 489.289 122.264 464.292L122.279 446.949C122.305 418.752 133.241 391.72 152.682 371.799C172.123 351.878 198.477 340.701 225.945 340.725L510.765 340.98L510.693 420.717L225.874 420.463C219.007 420.456 212.418 423.251 207.558 428.231C202.698 433.211 199.964 439.969 199.957 447.018L199.942 464.361C199.934 470.606 202.068 476.654 205.969 481.444C209.871 486.235 215.292 489.462 221.28 490.561L444.439 531.16C468.416 535.525 490.128 548.423 505.761 567.588C521.394 586.754 529.948 610.961 529.92 635.958L529.905 653.301C529.879 681.498 518.943 708.53 499.502 728.451C480.061 748.371 453.708 759.549 426.239 759.524Z"
        fill="#FF2ECC"
      />
      <path
        d="M877.84 420.738L877.911 341.001L586.618 340.741L586.707 241.069L509.029 241L508.673 639.685C508.644 671.407 520.893 701.84 542.724 724.29C564.555 746.74 594.181 759.368 625.083 759.395L877.536 759.621L877.608 679.884L625.154 679.658C614.853 679.649 604.978 675.44 597.701 667.956C590.424 660.473 586.341 650.329 586.351 639.755L586.547 420.478L877.84 420.738Z"
        fill="#FF2ECC"
      />
    </svg>
  );
};
