import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Layout } from '../layout';
import Link from '../shared/Link';
import { useLayout } from '../layout/useLayout';
import cn from 'classnames';

type Lang = { en: string; ru: string };

const siteTitle = {
  title: {
    en: 'Projects and customers',
    ru: 'Проекты и заказчики',
  },
};

const siteData = {
  description: {
    en:
      'Large government and commercial organizations and companies. Implementation of a video analysis and business process management platform',
    ru:
      'Крупные государственные и коммерческие организации и компании. Внедрение платформ видеоанализа и управления бизнес-процессами',
  },
};

const subText = [
  {
    en: 'OUR DIGITAL PRODUCTS, IDEAS AND CREATIVE ENERGY ARE EMBODIED IN',
    ru: 'НАШИ ЦИФРОВЫЕ ПРОДУКТЫ, ИДЕИ И КРЕАТИВНАЯ ЭНЕРГИЯ ВОПЛОЩЕНЫ В',
  },
  {
    en: 'PROJECTS',
    ru: 'ПРОЕКТАХ',
  },
  {
    en: ' FOR ICONIC CUSTOMERS',
    ru: 'ДЛЯ ЗНАКОВЫХ ЗАКАЗЧИКОВ',
  },
  {
    en: 'IN AN UNLIMITED RANGE OF INDUSTRIES.',
    ru: 'В НЕОГРАНИЧЕННОМ ОТРАСЛЕВОМ ДИАПАЗОНЕ.',
  },
];

const mobileSubText = [
  {
    en: 'OUR DIGITAL PRODUCTS, IDEAS AND CREATIVE ENERGY ARE EMBODIED IN',
    ru: 'НАШИ ЦИФРОВЫЕ ПРОДУКТЫ, ИДЕИ И КРЕАТИВНАЯ ЭНЕРГИЯ ВОПЛОЩЕНЫ В',
  },
  {
    en: 'PROJECTS',
    ru: 'ПРОЕКТАХ',
  },
  {
    en: ' FOR ICONIC CUSTOMERS',
    ru: 'ДЛЯ ЗНАКОВЫХ',
  },

  {
    en: 'CUSTOMERS',
    ru: 'ЗАКАЗЧИКОВ',
  },
  {
    en: 'IN AN UNLIMITED RANGE OF INDUSTRIES.',
    ru: 'В НЕОГРАНИЧЕННОМ ОТРАСЛЕВОМ ДИАПАЗОНЕ.',
  },
];

type Links = {
  id: number;
  title: { en: string; ru: string };
  hover: { en: string; ru: string };
  link: string;
  className: string;
};

const links: Links[] = [
  {
    id: 1,
    title: { en: 'Major Auto holding', ru: 'Major Автомобильный холдинг' },
    hover: {
      en: 'Access control for transport and integration with logistics',
      ru: 'Контроль доступа для транспорта и интеграция с логистикой',
    },
    link: 'https://www.major-auto.ru/',
    className: 'major',
  },
  {
    id: 2,
    title: {
      en: 'Pension Fund of the Russian Federation',
      ru: `Пенсионный фонд России`,
    },
    hover: {
      en: 'Budgeting, forecasting and reporting management',
      ru: 'Бюджетирование, прогнозирование и управление отчётностью',
    },
    link: 'https://es.pfrf.ru/',
    className: 'pfr',
  },
  {
    id: 3,
    title: { en: 'Gazprom Neft', ru: 'Газпромнефть' },
    hover: {
      en: 'Video analysis and cognitive data processing services',
      ru: 'Сервисы видеоанализа и когнитивной обработки данных',
    },
    link: 'https://gpn-trade.ru/',
    className: 'gazprom',
  },
  {
    id: 4,
    title: { en: 'MTS PJSC', ru: 'ПАО «МТС»' },
    hover: {
      en: 'Intelligent transport security system',
      ru: 'Интеллектуальная система транспортной безопасности',
    },
    link: 'https://moskva.mts.ru/personal',
    className: 'mts',
  },
  {
    id: 5,
    title: {
      en: 'Foundation for Assistance to Small Innovative Enterprises',
      ru: 'ФОНД СОДЕЙСТВИЯ ИННОВАЦИЯМ',
    },
    hover: {
      en:
        'Cognitive platform for the security of transport and stationary objects',
      ru:
        'Когнитивная платформа безопасности транспорта и стационарных объектов',
    },
    link: 'https://fasie.ru/',
    className: 'fond',
  },
  {
    id: 6,
    title: {
      en: 'PIVALDI, restaurant holding',
      ru: 'РЕСТОРАННЫЙ ХОЛДИНГ «ПИВАЛЬДИ»',
    },
    hover: {
      en: 'Biometric access and attendance system',
      ru: 'Биометрическая система доступа и учета посещаемости',
    },
    link: 'https://pivaldi.ru/',
    className: 'holding',
  },
  {
    id: 7,
    title: {
      en: 'Bank of Russia',
      ru: 'Банк России',
    },
    hover: {
      en: 'Video analytics security platform',
      ru: 'Платформа безопасности на базе видеоанализа',
    },
    link: 'https://www.cbr.ru',
    className: 'cbr',
  },
];

type Data = {
  title: Lang;
  titleMobile: Lang;
};

const data: Data = {
  title: { en: 'Clients', ru: 'Сделано' },
  titleMobile: {
    en: 'Clients',
    ru: 'Сдела&shy;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;но',
  },
};

const SubTitle = () => {
  const { lang } = useLayout();

  return (
    <>
      <WrapperSubTitle>
        <div>{subText[0][lang]}</div>
        <div>
          <div>{subText[1][lang]}</div>
          <div style={{ textIndent: '10em' }}>{subText[2][lang]}</div>
        </div>
        <div>{subText[3][lang]}</div>
      </WrapperSubTitle>
      <MobileWrapperSubTitle>
        <div>{mobileSubText[0][lang]}</div>
        <div>{mobileSubText[1][lang]}</div>
        <div style={{ textIndent: '1.3em' }}>{mobileSubText[2][lang]}</div>
        <div style={{ textAlign: 'right' }}>{mobileSubText[3][lang]}</div>
        <div>{mobileSubText[4][lang]}</div>
      </MobileWrapperSubTitle>
    </>
  );
};

const Clients = () => {
  const [id, setId] = useState<null | number>(null);

  return (
    <Layout
      titlePage={data.title}
      titlePageMobile={data.titleMobile}
      title={siteTitle.title}
      description={siteData.description}
      rendererSubTitle={() => <SubTitle />}
    >
      {({ lang }: { lang: 'en' | 'ru' }) => (
        <Container>
          <WrapperContent>
            {links.map((item) => {
              const active = id === item.id;
              return (
                <Content
                  onMouseEnter={() => setId(item.id)}
                  onMouseLeave={() => setId(null)}
                  onClick={() => setId(item.id)}
                  key={item.id}
                  className={cn({ [item.className]: true })}
                >
                  {item.title[lang] && (
                    <>
                      {active ? (
                        <a href={item.link} target={'_blank'}>
                          <TextActive>{item.hover[lang]}</TextActive>
                        </a>
                      ) : (
                        <WrapperText>{item.title[lang]}</WrapperText>
                      )}
                    </>
                  )}
                </Content>
              );
            })}
          </WrapperContent>
        </Container>
      )}
    </Layout>
  );
};

export default Clients;

const Container = styled.div`
  padding: 5.07vw 5.76vw 2.5vw 3.96vw;
  width: 100%;
`;

const WrapperContent = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-areas:
    ". major major pfr pfr ."
    "gazprom gazprom mts mts fond fond"
    ". holding holding cbr cbr ."
;

  &.block {
    border-right: 0.2vw solid #071013;
    border-left: 0.2vw solid #071013;
  }

  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: block;
    grid-template-columns: none;
    &.block {
      border: none;
    }
  }
`;

const Content = styled.div<{ lang?: 'en' | 'ru' }>`
  width: 31.4vw;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 6.6vw 0;
  height: 22.78vw;
  cursor: pointer;
  color: #071013;
  text-align: center;
  font-family: 'Atyp Display', sans-serif;
  text-transform: uppercase;
  &.major {
    border-right: 0.2vw solid #071013;
    grid-area: major;
  }

  &.gazprom {
    grid-area: gazprom;
  }
  
  &.pfr {
    grid-area: pfr;

    & > div {
      max-width: ${({ lang }) => (lang === 'ru' ? 20.62 : 22.1)}vw;
      width: 100%;
    }
  }

  &.mts {
    border-left: 0.2vw solid #071013;
    grid-area: mts;
  }

  &.fond {
    border-left: 0.2vw solid #071013;
    grid-area: fond;

    & > div {
      max-width: ${({ lang }) => (lang === 'ru' ? 20.62 : 28)}vw;
      width: 100%;
    }
  }

  &.holding {
    border-right: 0.2vw solid #071013;
    grid-area: holding;
    & > div {
      max-width: 28vw;
      width: 100%;
    }
  }

  &.cbr {
    grid-area: cbr;

    & > div {
      max-width: ${({ lang }) => (lang === 'ru' ? 20.62 : 23.1)}vw;
      width: 100%;
    }
    & > a {
      width: 28vw;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    width: 100%;
    border-bottom: 0.63vw solid #000000;
    height: 67.81vw;
    &:last-child {
      border-bottom: none;
    };
    & > div {
      max-width: 100%;
    }

    &.major {
      border-right: none;
    }

    &.pfr {
      border-right: none;
      border-left: none;

      & > div {
        max-width: ${({ lang }) => (lang === 'ru' ? 88 : 77)}vw;
        width: 100%;
      }
    }

    &.mts {
      border-left: none;
      grid-column: 2;
      margin-left: 0;
    }

    &.holding {
      border: none;
      
      & > div {
        max-width: 100%;
      }
    }

    &.fond {
      border-left: none;
      border-right: none;
      margin-left: 0;

      & > div {
        max-width: ${({ lang }) => (lang === 'ru' ? 76 : 90)}vw;
        width: 100%;
      }
    }
    &.cbr {
      border-left: none;
      border-top: 0.63vw solid #000000;
      & > div {
        max-width: 100%;
      }
      & > a {
        width: 100%;
      }
    }
  }
`;

const WrapperSubTitle = styled.div`
  width: 62.8vw;
  margin: 1.25vw 0 2.64vw auto;
  & > div {
    display: flex;
    justify-content: flex-end;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: none;
  }
`;

const MobileWrapperSubTitle = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: grid;
    height: 100%;
    width: 100%;
    margin: 10vw 0;
  }
`;

const TextActive = styled.div`
  font-size: 2vw;
  line-height: 3.1vw;
  color: #071013;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 6vw;
    line-height: 10vw;
  }
`;

const WrapperText = styled.div`
  line-height: 3vw;
  font-size: 2.78vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 9.69vw;
    line-height: 12.19vw;
  }
`;
