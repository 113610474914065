import React from 'react';
import { MapIcon } from '../../../icons/mapIcon';
import styled from 'styled-components/macro';

type Props = {
  lat: number;
  lng: number;
  text: string;
};

export const Marker: React.FC<Props> = ({ text }): JSX.Element => {
  return (
    <Container>
      <MapIcon />
    </Container>
  );
};
const Container = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    width: 100%;
    height: 100%;
  }
`;
