import React, { Fragment, useState } from 'react';
import { Layout } from '../layout';
import styled from 'styled-components/macro';
import Link from '../shared/Link';
import cn from 'classnames';

type LinkType = {
  id: number;
  title: {
    en: string;
    ru: string;
  };
  url: string;
  urlMobile: string;
  zIndex: number;
  hover: {
    en: string;
    ru: string;
  };
  className: string;
};

const links: LinkType[] = [
  {
    id: 1,
    title: { en: 'Mission', ru: 'Призвание' },
    url: '/mission',
    urlMobile: '/mission',
    zIndex: 1,
    hover: { en: 'Mission', ru: 'Призвание' },
    className: 'black',
  },
  {
    id: 2,
    title: { en: 'Creations', ru: 'Создано' },
    url: '/creations',
    urlMobile: '/creations',
    zIndex: 2,
    hover: { en: 'Creations', ru: 'Создано' },
    className: 'black',
  },
  {
    id: 3,
    title: { en: 'Clients', ru: 'Сделано' },
    url: '/clients',
    urlMobile: '/clients',
    zIndex: 3,
    hover: { en: 'Clients', ru: 'Сделано' },
    className: 'black',
  },

  {
    id: 4,
    title: { en: 'Get the future', ru: 'Made-to-measure' },
    url: '/madetomeasure',
    urlMobile: '/madetomeasure',
    zIndex: 4,
    hover: { en: 'Get the future', ru: 'Разработка «на заказ»' },
    className: 'black',
  },
  {
    id: 5,
    title: { en: 'Heaven', ru: 'Heaven' },
    url: '/heaven',
    urlMobile: '/heaven',
    zIndex: 5,
    hover: { en: 'Heaven', ru: 'Heaven' },
    className: 'pink',
  },
  {
    id: 6,
    title: { en: 'We', ru: 'Мы' },
    url: '/we',
    urlMobile: '/we',
    zIndex: 6,
    hover: { en: 'We', ru: 'Мы' },
    className: 'black',
  },
  {
    id: 7,
    title: { en: 'Get on board', ru: 'Go с нами' },
    url: '/geton',
    urlMobile: '/geton',
    zIndex: 7,
    hover: { en: 'Get on board', ru: 'Go с нами' },
    className: 'black',
  },
  {
    id: 8,
    title: { en: 'Contact us', ru: 'Войти в контакт' },
    url: '/contacts',
    urlMobile: '/contacts',
    zIndex: 8,
    hover: { en: 'Contact us', ru: 'Войти в контакт' },
    className: 'black',
  },
  {
    id: 9,
    title: { en: 'Ecosystems', ru: 'Экосистемы' },
    url: '/ecosystems',
    urlMobile: '/ecosystems',
    zIndex: 9,
    hover: { en: 'Ecosystems', ru: 'Экосистемы' },
    className: 'black',
  },
  {
    id: 10,
    title: { en: 'Formalities', ru: 'Формальности' },
    url: '/formalities?page=1',
    urlMobile: '/formalities?page=0',
    zIndex: 10,
    hover: { en: 'Formalities', ru: 'Формальности' },
    className: 'black',
  },
];

const siteTitle = {
  title: {
    en: 'Skytrack is a digital solutions and artificial intelligence company',
    ru: 'Скайтрэк - цифровая платформа видеоанализа, искусственного интеллекта и дополненной реальности',
  },
};

const siteData = {
  description: {
    en:
      'Innovative IT company since 2014. Developer of solutions in the field of biometric systems, video monitoring systems, cognitive systems for transport security, security of stationary objects, digital platforms for data analysis and presentation. Skolkovo Resident',
    ru: 'Инновационная ИТ- компания с 2014 года. Создание цифровых платформ анализа и представления данных, разработка решений в области биометрических систем, систем видеомониторинга, когнитивных систем транспортной безопасности, безопасности стационарных объектов. Резидент Сколково'
  },
};

const IndexPage = () => {
  const [id, setId] = useState<null | number>(null);
  return (
    <Layout title={siteTitle.title} description={siteData.description}>
      {({ lang }: { lang: 'en' | 'ru' }) => (
        <>
          <Links>
            {links.map((item, index) => {
              const active = id === item.id;
              return (
                <Fragment key={item.id}>
                  <ItemLinkWrapper
                    zIndex={item.zIndex}
                    top={index * 6.52 + 2.84}
                    onMouseEnter={() => setId(item.id)}
                    onMouseLeave={() => setId(null)}
                  >
                    <Link to={item.url}>
                      <ItemLink
                        className={cn({
                          [item.className]: true,
                        })}
                      >
                        {active ? item.hover[lang] : item.title[lang]}
                      </ItemLink>
                    </Link>
                  </ItemLinkWrapper>
                </Fragment>
              );
            })}
          </Links>
          <MobileWrapper>
            {links.map((item) => {
              return (
                <Link to={item.urlMobile} key={item.id}>
                  <MobileContent
                    className={cn({
                      [item.className]: true,
                    })}
                  >
                    {item.title[lang]}
                  </MobileContent>
                </Link>
              );
            })}
          </MobileWrapper>
        </>
      )}
    </Layout>
  );
};
export default IndexPage;

const ItemLink = styled.div`
  font-family: 'Atyp Display', sans-serif;
  font-style: normal;
  font-size: 9.02vw;
  line-height: 9.02vw;
  letter-spacing: -0.04em;
  &.black {
    color: #071013;
  }
  &.pink {
    color: #ff2ecc;
  }
  @media screen and (max-width: ${({ theme }) => theme.table_smallest}) {
    padding-left: max(1.4vw, env(safe-area-inset-left));
    padding-right: max(1.4vw, env(safe-area-inset-right));
  }
`;

const Links = styled.div`
  margin-top: 5.5vw;
  position: relative;
  height: 68.33vw;
  overflow: hidden;
  margin-bottom: 7.77vw;
  ::after {
    background: #071013;
    content: '';
    width: 100%;
    height: 0.18vw;
    position: absolute;
    bottom: 0;
    z-index: 11;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: none;
    height: 105.94vw;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    ::after {
      display: none;
    }
  }
`;

const ItemLinkWrapper = styled.div<{ zIndex: number; top: number }>`
  justify-content: space-between;
  position: absolute;
  top: ${({ top }) => top}vw;
  display: flex;
  height: 9.6vw;
  border-top: 0.21vw solid #071013;
  overflow: hidden;
  z-index: ${({ zIndex }) => zIndex};
  width: 100%;
  background: #fff;
  transition: ease transform 0.4s;
  cursor: pointer;
  &:hover {
    transform: translateY(-2.84vw);
    background: #baff49;
  }
  &:hover ${ItemLink} {
    color: #071013;
  }
  a {
    width: 100%;
    padding-left: 1.38vw;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-family: 'Atyp Display', sans-serif;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    height: 132vw;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    & > a {
      display: grid;
      width: 19vw;
      font-weight: 400;
      font-size: 15vw;
      line-height: 17.5vw;
      writing-mode: vertical-lr;
      transform: rotate(180deg);
      border-left: 0.63vw solid #071013;
      padding-top: 1vw;
      padding-bottom: 1vw;
      color: #071013;
    }
  }
`;

const MobileContent = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    &.black {
      color: #071013;
    }
    &.pink {
      color: #ff2ecc;
    }
  }
`;
