import React from 'react';
import styled from 'styled-components/macro';

const Partners = () => {
  return (
    <Container>
      <Content className={'one'}>Security Platform</Content>
      <Content className={'two'}>МТС</Content>
      <Content className={'three'}>КИТ Консалтинг</Content>
      <Content className={'four'}>CREDO Consulting</Content>
    </Container>
  );
};

export default Partners;

const Container = styled.div`
  padding: 10.42vw 5.28vw 4.03vw;
  width: 100%;
  background: linear-gradient(0deg, #baff49 0%, rgba(186, 255, 73, 0) 100%);
  .one {
    margin: 0 0 3.68vw 4.86vw;
  }
  .two {
    margin: 0 0 3.75vw 21.32vw;
  }
  .three {
    margin-bottom: 7.78vw;
  }
  .four {
    margin-left: 27.51vw;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    .one {
      margin: 0 0 25vw 15vw;
    }
    .two {
      margin: 0 0 25vw 45vw;
    }
    .three {
      margin-bottom: 25vw;
    }
    .four {
      margin-bottom: 25vw;
      margin-left: 20vw;
    }
  }
`;

const Content = styled.div`
  display: flex;
  font-family: 'Atyp Display';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 1.94vw;
  color: #000000;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 5.63vw;
    line-height: 8.13vw;
    margin-bottom: 25vw;
  }
`;
