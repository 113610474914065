import React from 'react';
import styled from 'styled-components/macro';
import { Layout } from '../layout';
import { SkyTrackIcon } from '../icons';
import { useLayout } from '../layout/useLayout';

type Data = Record<string, { en: string; ru: string }>;

const siteTitle = {
  title: {
    en: 'Mission',
    ru: 'Миссия',
  },
};

const siteData = {
  description: {
    en: 'We create innovative solutions for creative activity and safe life',
    ru:
      'Создаем инновационные решения для созидательной деятельности и безопасной жизни',
  },
};

const subText = [
  {
    en:
      'Get up in the morning, squint at the nascent sun or at the glistening of a streetlight on the snow, take a deep breath of the',
    ru:
      'Встаем по утрам, жмуримся нарождающемуся солнцу или отблеску фонаря на снегу, вдыхаем полной грудью воздух',
  },
  {
    en:
      'street air and rush through Moscow, leaving a trace of coffee aroma behind the taxi or the shared car.',
    ru:
      'улицы, мчимся по Москве, оставляя след ароматного кофе за своим такси или каршерингом.',
  },
];

const data: Data = {
  title: { en: 'Mission', ru: 'Призвание' },
  titleMobile: { en: 'Missi&shy;on', ru: 'Призва&shy;ние' },
  office: { en: 'Office.', ru: 'Офис.' },
  computer: { en: 'Computer.', ru: 'Комп.' },
  code: { en: 'Code.', ru: 'Код.' },
  aLotOfCode: { en: 'A lot of code.', ru: 'Много кода.' },
  aLotOfIdeas: { en: 'A lot of ideas.', ru: 'Много идей.' },
  ideas: { en: 'Ideas are always with us.', ru: 'Идеи с нами всегда.' },
  text: {
    en:
      "Ideas are the forerunners of groundbreaking developments and unparalleled digital solutions. We invent and bring what we invent to life, making the world better, safer and more comfortable. That's how we roll. Ordinary life, extraordinary team. Ideas, Developments, Digital Solutions and Ideas again: these constitute our mission, our cycle, our real life in the virtual world governed by AI and AR.",
    ru:
      'Идеи — предвестники крутых разработок, цифровых решений, которых мир еще не знал. Придумываем и воплощаем, чтобы мир стал лучше, комфортнее, безопаснее. Так мы живем. Простая жизнь неординарной команды. Идеи, Разработки, Цифровые решения и снова Идеи — вот наше призвание, наш цикл, наша реальная жизнь в виртуальном пространстве, где правят AI и AR.',
  },
};

const SubTitle = () => {
  const { lang } = useLayout();
  return (
    <WrapperSubTitle>
      <div className={'subText'}>{subText[0][lang]}</div>
      <div>{subText[1][lang]}</div>
    </WrapperSubTitle>
  );
};

const Mission = () => {
  return (
    <Layout
      titlePage={data.title}
      titlePageMobile={data.titleMobile}
      title={siteTitle.title}
      description={siteData.description}
      rendererSubTitle={() => <SubTitle />}
    >
      {({ lang }: { lang: 'en' | 'ru' }) => (
        <Container>
          <Logo>
            <SkyTrackIcon />
          </Logo>
          <Body>
            <div>
              <WrapperText>{data.office[lang]}</WrapperText>
              <WrapperText className={'comp'}>
                {data.computer[lang]}
              </WrapperText>
              <WrapperText className={'code'}>{data.code[lang]}</WrapperText>
              <WrapperText className={'aLotOfCode'}>
                {data.aLotOfCode[lang]}
              </WrapperText>
              <WrapperText className={'aLotOfIdeas'}>
                {data.aLotOfIdeas[lang]}
              </WrapperText>
              <WrapperText style={{ marginLeft: '24.5vw' }}>
                {data.ideas[lang]}
              </WrapperText>
            </div>
            <MissionText>{data.text[lang]}</MissionText>
          </Body>
        </Container>
      )}
    </Layout>
  );
};

export default Mission;

const Container = styled.div`
  display: grid;
  grid-template-columns: 28.9vw 1fr;
  grid-gap: 5.3vw;
  padding: 5.56vw 9.03vw 10.42vw 0;
  @media screen and (max-width: ${({ theme }) => theme.table_smallest}) {
    padding-left: max(1.4vw, env(safe-area-inset-left));
    padding-right: max(1.4vw, env(safe-area-inset-right));
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    grid-template-columns: none;
    grid-gap: 19.77vw;
    padding: 9.3vw 0 0 0;
  }
`;

const Logo = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 42.6vw;
  margin-left: -6.9vw;
  & > svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    height: 126.98vw;
  }
`;

const Body = styled.div`
  font-family: 'Atyp Display', sans-serif;
  font-style: normal;
  line-height: 2vw;
  color: #071013;
  font-size: 1.25vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 5.35vw;
    line-height: 7.91vw;
    padding: 0 2.33vw;
  }
`;

const MissionText = styled.div`
  max-width: 19.3vw;
  width: 100%;
  margin: 7.1vw 0 0 24.5vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    margin: 20.93vw 0 30.23vw 0;
    max-width: 100%;
  }
`;

const WrapperText = styled.div`
  &.comp {
    margin-left: 33vw;
    @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
      text-align: right;
      margin: 6.05vw 3.95vw 6.05vw 0;
    }
  }

  &.code {
    margin-left: 16.5vw;
    @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
      text-align: center;
      margin-left: 9.3vw;
    }
  }

  &.aLotOfCode {
    text-align: right;
    @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
      text-align: left;
      margin: 12.09vw 0 15.12vw;
    }
  }

  &.aLotOfIdeas {
    margin-left: 8.5vw;
    @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
      text-align: right;
      margin: 0 15.12vw 18.14vw 0;
    }
  }
`;

const WrapperSubTitle = styled.div`
  width: 65.8vw;
  margin: 1.39vw 0 3.47vw auto;
  & > div {
    &.subText {
      text-indent: 16vw;
      @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
        text-indent: 53.49vw;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    width: 100%;
    margin: 6.51vw 0 13.02vw 0;
  }
`;
