import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Layout } from '../layout';
import { Form } from '../feature/form';
import { useLayout } from '../layout/useLayout';
import { Button } from '../components/button';

type Data = Record<string, { en: string; ru: string }>;

const siteTitle = {
  title: {
    en: 'Development of software and mobile applications',
    ru: 'Разработка программного обеспечения и мобильных приложений',
  },
};

const siteData = {
  description: {
    en: 'Development of desktop and mobile applications "to order"',
    ru: 'Разработка прикладных систем и мобильных приложений «на заказ»',
  },
};

const subText = [
  {
    en: 'You are an entrepreneur. You are a company. You have',
    ru: 'Вы – предприниматель. Вы – компания. У вас есть план,',
  },
  {
    en: 'a plan. You want to become bigger',
    ru:
      'идея стать лучше, масштабнее, развиваться быстрее, предвосхищать и в итоге выигрывать.',
  },
  {
    en:
      ' and better, to evolve faster, to anticipate and, consequently, to win.',
    ru: '',
  },
  {
    en: 'You are an entrepreneur. You are a company.',
    ru: 'Вы – предприниматель. Вы – компания.',
  },
  {
    en: 'You have',
    ru: 'У вас есть план,',
  },
];

const data: Data = {
  title: { en: 'Made-to-measure', ru: 'Made-to-measure' },
  titleMobile: { en: 'Made-to-measu re', ru: 'Made-to-measu re' },
  transform: {
    en:
      'We transform the possibilities that the world of innovation and technology offers us into the driving force behind your ideas.',
    ru:
      'Мы преобразуем возможности, которые дает мир инноваций и технологий в двигатель для вашей идеи.',
  },
  tool: {
    en:
      ' We will give you the tool for reaching success, which is based on artificial intelligence, augmented reality and the Internet of things.',
    ru:
      'Дисциплинированно и методично продвигаясь совместно с вами от концепции к разработке и конечному продукту, мы вооружим вас инструментом успеха на базе искусственного интеллекта,',
  },
  product: {
    en:
      "With us, you'll progress steadily from concept to development to the final product.",
    ru: 'дополненной реальности, интернета вещей.',
  },
  platform: {
    en:
      'A cloud platform with complex business logic, built-in AI components and IoT devices? A video analysis system offering industry-specific solutions?',
    ru:
      'Облачная платформа со сложной бизнес-логикой, встроенными AI-компонентами и интегрированными IoT устройствами? Система видеоанализа для решения отраслевых задач? Выдающееся',
  },
  devices: {
    en: 'A one-of-a-kind marketing app with AR?',
    ru:
      'мобильное приложение с AR для маркетинга? Это только часть того, что мы умеем, что готовы разработать для вас.',
  },

  just: {
    en:
      'These are all just a part of what we are able to do. These are only a fraction of what we can develop for you.',
    ru: '',
  },
  skytrack: {
    en: 'SKYTRACK is your made-to-measure FUTURE.',
    ru: 'SKYTRACK – ваше БУДУЩЕЕ «made-to-measure».',
  },
  button: {
    en: 'GET THE FUTURE',
    ru: 'заказать будущее',
  },
  sent: { en: 'Sent', ru: 'Отправлено' },
};

const textTextarea = {
  en: 'Idea-project-task-question',
  ru: 'Идея-проект-задача-вопрос',
};

const textPlaceholder = {
  en:
    'You can write something like this: “We need a nice-looking, smart, cheerful mobile app with AR for retail”',
  ru:
    'Здесь вы можете, например, написать: "Нам нужно красивое, умное, весёлое мобильное приложение с дополненной реальностью для ритейла".',
};

const SubTitle = () => {
  const { lang } = useLayout();
  return (
    <WrapperSubTitle>
      <div className={'subText'}>{subText[0][lang]}</div>
      <div className={'mobileSubText'}>{subText[3][lang]}</div>
      <div className={'mobileSubText'} style={{ textIndent: '7em' }}>
        {subText[4][lang]}
      </div>
      <div>{subText[1][lang]}</div>
      <div>{subText[2][lang]}</div>
    </WrapperSubTitle>
  );
};
const MadeToMeasure = () => {
  const [clickedButton, setButton] = useState<string | boolean>('');
  const [fetched, setFetched] = useState<boolean>(false);
  const { lang } = useLayout();

  useEffect(() => {
    if (fetched) {
      setTimeout(() => {
        setFetched(false);
      }, 2000);
    }
  }, [fetched]);

  const Content = () => {
    return (
      <Container>
        <Text>
          <div style={{ textIndent: '12em', marginBottom: '1.74vw' }}>
            {data.transform[lang]}
          </div>
          <div style={{ width: '76.39vw', marginBottom: '0.69vw' }}>
            {data.tool[lang]}
          </div>
          <div style={{ textAlign: 'right', marginBottom: '2.78vw' }}>
            {data.product[lang]}
          </div>
          <div>{data.platform[lang]}</div>
          <div style={{ width: '80.39vw' }}>{data.devices[lang]}</div>
          <div>{data.just[lang]}</div>
          <div
            style={{
              marginBottom: '1.39vw',
              marginTop: '3.47vw',
              textAlign: 'right',
            }}
          >
            {data.skytrack[lang]}
          </div>
        </Text>
        <MobileText>
          <div>{data.transform[lang]}</div>
          <div className={'mobileContent'}>{data.tool[lang]}</div>
          <div>{data.product[lang]}</div>
          <div>{data.platform[lang]}</div>
          <div>{data.devices[lang]}</div>
          <div className={'mobileContent'}>{data.just[lang]}</div>
          <div>{data.skytrack[lang]}</div>
        </MobileText>
        <WrapperButton>
          <Button name="name" onClick={(e: any) => setButton(true)}>
            {data.button[lang]}
          </Button>
        </WrapperButton>
      </Container>
    );
  };

  return (
    <Layout
      title={siteTitle.title}
      description={siteData.description}
      titlePage={data.title}
      titlePageMobile={data.titleMobile}
      rendererSubTitle={() => <SubTitle />}
    >
      {() => (
        <div>
          {clickedButton ? (
            <WrapperForm>
              <Form
                setButton={setButton}
                textTextarea={textTextarea}
                textPlaceholder={textPlaceholder}
              />
            </WrapperForm>
          ) : (
            <>
              <Content />
            </>
          )}
        </div>
      )}
    </Layout>
  );
};
export default MadeToMeasure;

const Container = styled.div`
  padding: 3.33vw 1.39vw 0 1.39vw;
  @media screen and (max-width: ${({ theme }) => theme.table_smallest}) {
    padding-left: max(1.4vw, env(safe-area-inset-left));
    padding-right: max(1.4vw, env(safe-area-inset-right));
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    padding: 10.23vw 2.1vw 0;
  }
`;

const WrapperForm = styled.div`
  width: 58.54vw;
  margin: auto;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    width: 100%;
  }
`;

const Text = styled.div`
  font-family: 'Atyp Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.53vw;
  line-height: 1.88vw;
  text-transform: uppercase;
  color: #071013;
  width: 86.3vw;
  margin-bottom: 2.85vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: none;
  }
`;

const WrapperButton = styled.div`
  margin-bottom: 2.92vw;
  display: flex;
  justify-content: center;

  button {
    font-family: 'Atyp Display', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.78vw;
    line-height: 3.19vw;
    text-align: center;
    text-transform: uppercase;
    border: 0.28vw solid #071013;
    box-sizing: border-box;
    color: #071013;
    background: #fff;
    &:hover {
      cursor: pointer;
      background: #baff49;
    }
    @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
      border-radius: 14.88vw;
      font-size: 6.05vw;
      line-height: 6.98vw;
      width: 95.12vw;
      height: 13.49vw;
      background: #baff49;
      border: 0.63vw solid #071013;
      margin-bottom: 12vw;
    }
  }
`;
const WrapperSubTitle = styled.div`
  width: 66.74vw;
  margin: 1.11vw 0 2.5vw auto;
  .subText {
    text-indent: 10.5em;
  }
  .mobileSubText {
    display: none;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    height: 100%;
    width: 100%;
    margin-top: 6vw;
    margin-bottom: 6vw;
    .subText {
      display: none;
    }
    .mobileSubText {
      display: flex;
    }
  }
`;

const MobileText = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-family: 'Atyp Display', sans-serif;
    display: grid;
    font-style: normal;
    font-weight: 400;
    font-size: 5.35vw;
    line-height: 7.91vw;
    color: #071013;
    margin-bottom: 6vw;
    width: 100%;
    & > div {
      width: 90.93vw;
      margin-bottom: 4vw;
    }
    .mobileContent {
      margin-bottom: 0;
    }
  }
`;
