import React, { useState } from 'react';
import { Layout } from '../layout';
import styled from 'styled-components/macro';
import { Form } from '../feature/form';
import { useLayout } from '../layout/useLayout';
import { Button } from '../components/button';
import { Maps } from '../feature/maps';

type Data = Record<string, { en: string; ru: string }>;

const siteTitle = {
  title: {
    en: 'Contacts',
    ru: 'Контакты',
  },
};

const siteData = {
  description: {
    en: 'Contacts for communication with the Skytrack office. Feedback form',
    ru: 'Контакты для связи с офисом Скайтрэк. Форма обратной связи',
  },
};

const data: Data = {
  title: { en: 'Contact us', ru: 'Войти в контакт' },
  titleMobile: { en: 'Con&shy tact  &nbsp;&nbsp;us', ru: 'Войти в контакт' },
  text: {
    en: 'We are located in the center of the center of Moscow',
    ru: 'Находимся в Центре центра Москвы',
  },
  phone: { en: 'phone numbers', ru: 'Телефон' },
  sales: { en: 'Sales', ru: 'Продажи' },
  questions: { en: 'General issues', ru: 'Общие вопросы' },
  request: {
    en: 'Place an order',
    ru: 'Заказать будущее',
  },
  email: { en: 'Email', ru: 'Электронная почта' },
  address: { en: 'the address', ru: 'Адрес' },
  city: { en: '123104, Moskva,', ru: '123104, г. Москва,' },
  dom: { en: 'ul. Malaya Bronnaya, d. 2, building 1', ru: 'ул. Малая Бронная д. 2, стр. 1' },
  sent: { en: 'Sent', ru: 'Отправлено' },
};

const textTextarea = {
  en: 'Idea-project-task-question',
  ru: 'Идея-проект-задача-вопрос',
};

const textPlaceholder = {
  en:
    'You can write something like this: “We need a nice-looking, smart, cheerful mobile app with AR for retail”',
  ru:
    'Здесь вы можете, например, написать: "Нам нужно красивое, умное, весёлое мобильное приложение с дополненной реальностью для ритейла".',
};

const Contacts = () => {
  const [clickedButton, setButton] = useState<string | boolean>('');
  const { lang } = useLayout();

  const Content = () => {
    return (
      <ContainerContacts>
        <Block>
          <InfoBlock>
            <div>
              <BlockPhone>
                <Phone>{data.phone[lang]}</Phone>
                <InfoPhone style={{ marginBottom: '1.46vw' }}>
                  <Connection>{data.sales[lang]}</Connection>
                  <Number>+7 (499) 322 74 44</Number>
                </InfoPhone>
                <InfoPhone>
                  <Connection>{data.questions[lang]}</Connection>
                  <Number>+7 (499) 322 77 72</Number>
                </InfoPhone>
              </BlockPhone>
              <WrapperButton>
                <Button name="name" onClick={(e: any) => setButton(true)}>
                  {data.request[lang]}
                </Button>
              </WrapperButton>
            </div>
            <div>
              <MailBlock>
                <Mail>{data.email[lang]}</Mail>
                <Email>
                  <p>office@skytracking.ru</p>
                  <div>office@</div>
                  <div>skytracking.ru</div>
                </Email>
              </MailBlock>
              <Address>
                <div>{data.address[lang]}</div>
                <p>{data.city[lang]}</p>
                <p>{data.dom[lang]}</p>
              </Address>
            </div>
          </InfoBlock>
          <WrapperMobileButton>
            <Button name="name" onClick={(e: any) => setButton(true)}>
              {data.request[lang]}
            </Button>
          </WrapperMobileButton>
        </Block>
        <Maps />
      </ContainerContacts>
    );
  };

  return (
    <Layout
      titlePage={data.title}
      titlePageMobile={data.titleMobile}
      title={siteTitle.title}
      description={siteData.description}
      styleTitle={{ marginBottom: '3.47vw', borderBottom: 'none' }}
    >
      {({ lang }: { lang: 'en' | 'ru' }) => (
        <Container>
          <Title>{data.text[lang]}</Title>
          {clickedButton ? (
            <WrapperForm>
              <Form
                setButton={setButton}
                textTextarea={textTextarea}
                textPlaceholder={textPlaceholder}
              />
            </WrapperForm>
          ) : (
            <Content />
          )}
        </Container>
      )}
    </Layout>
  );
};
export default Contacts;

const Container = styled.div`
  width: 100%;
  padding-top: 3.47vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    padding-top: 15.63vw;
  }
`;

const ContainerContacts = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    overflow: hidden;
  }
`;

const Block = styled.div`
  padding: 0 1.39vw 0 1.39vw;
  font-family: 'Atyp Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.78vw;
  line-height: 3.19vw;
  color: #071013;
  text-transform: uppercase;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    padding: 0 3.49vw 0 3.49vw;
  }
`;

const WrapperForm = styled.div`
  width: 100%;
  margin: auto;
`;

const BlockPhone = styled.div`
  margin: 0 8.06vw 0 11.81vw;
  white-space: nowrap;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    margin: 0;
  }
`;

const Number = styled.div`
  font-family: 'Atyp Display 600', sans-serif;
  white-space: nowrap;
  margin-left: 1.39vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 9.69vw;
    line-height: 12.19vw;
    margin: 0;
  }
`;

const Email = styled.div`
  font-family: 'Atyp Display 600', sans-serif;
  font-style: normal;
  & > div {
    display: none;
  }
  & > p {
    font-size: 2.78vw;
    line-height: 3.19vw;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    & > div {
      display: flex;
      font-size: 10vw;
      line-height: 12.63vw;
    }
    & > p {
      display: none;
    }
  }
`;

const Title = styled.div`
  padding: 0 0 8.33vw 1.39vw;
  width: 40.97vw;
  font-family: 'Atyp Display 600', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.78vw;
  line-height: 3.19vw;
  text-transform: uppercase;
  color: #071013;
  @media screen and (max-width: ${({ theme }) => theme.table_smallest}) {
    padding-left: max(1.4vw, env(safe-area-inset-left));
    padding-right: max(1.4vw, env(safe-area-inset-right));
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 9.69vw;
    line-height: 12.19vw;
    width: 100%;
    padding: 0 0 21.25vw 3.49vw;
  }
`;

const InfoBlock = styled.div`
  display: flex;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: grid;
  }
`;

const InfoPhone = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: grid;
  }
`;

const Phone = styled.div`
  font-size: 1.53vw;
  line-height: 1.88vw;
  margin: 0 0 1.39vw 10.38vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 5.31vw;
    line-height: 7.81vw;
    margin: 0 0 3.13vw 0;
  }
`;
const Connection = styled.div`
  font-family: 'Atyp Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 1.6vw;
  color: #9b9b9b;
  text-transform: none;
  margin-left: auto;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: none;
  }
`;

const Mail = styled.div`
  font-size: 1.53vw;
  line-height: 1.88vw;
  margin-bottom: 1.39vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 5.31vw;
    line-height: 7.81vw;
    margin-bottom: 2.79vw;
  }
`;

const MailBlock = styled.div`
  margin-bottom: 6.81vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    margin-bottom: 24.88vw;
    margin-top: 19.53vw;
  }
`;

const Address = styled.div`
  width: 39.72vw;
  font-size: 1.53vw;
  & > div {
    text-transform: uppercase;
    margin-bottom: 1.39vw;
    line-height: 1.88vw;
  }
  & > p {
    line-height: 2.01vw;
    text-transform: none;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 5.31vw;
    width: 93.75vw;
    margin-bottom: 21.16vw;
    & > div {
      margin-bottom: 3.13vw;
      line-height: 7.81vw;
    }
    & > p {
      line-height: 8.44vw;
    }
  }
`;

const WrapperButton = styled.div`
  margin: 6.88vw 0 6.53vw 0;
  button {
    font-family: 'Atyp Display', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.78vw;
    line-height: 3.19vw;
    text-align: center;
    text-transform: uppercase;
    border: 0.28vw solid #071013;
    box-sizing: border-box;
    color: #071013;
    background: #fff;

    &:hover {
      cursor: pointer;
      background: #baff49;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.table_smallest}) {
    padding-left: max(1.4vw, env(safe-area-inset-left));
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: none;
  }
`;

const WrapperMobileButton = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: flex;
    button {
      border-radius: 14.88vw;
      font-size: 6.05vw;
      line-height: 6.98vw;
      width: 95.12vw;
      height: 13.49vw;
      background: #baff49;
      border: 0.63vw solid #071013;
      margin-bottom: 12vw;
    }
  }
`;
