import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components/macro';
import { Layout } from '../layout';
import { Button } from '../components/button';
import { VerticalLine } from '../components/lineAnimation';
import { Form } from '../feature/form';
import Partners from '../feature/partners/partners';

type Data = Record<string, { en: string; ru: string }>;

const siteTitle = {
  title: {
    en: 'Partnership',
    ru:
      'Partnership program for system integrators and hardware and software developers',
  },
};

const siteData = {
  description: {
    en:
      'A team of professionals in software development, video analysis and artificial intelligence',
    ru:
      'Партнерская программа для системных интеграторов, производителей оборудования и программного обеспечения',
  },
};

const data: Data = {
  title: { en: 'Get on board', ru: 'Go с нами' },
  titleMobile: {
    en: 'Get &nbsp;&nbsp;on board',
    ru: 'Go &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;с нами',
  },
  text: {
    en: 'Tap into our experience, expertise, clients and markets',
    ru: 'Делимся нашим опытом, знаниями, заказчиками, рынками',
  },
  become: { en: 'Become a partner', ru: 'Стать партнёром' },
  our: { en: 'Our partners', ru: 'Стали партнёрами' },
  sent: { en: 'sent', ru: 'Отправлено' },
};

const textTextarea = {
  en: 'Comment',
  ru: 'Комментарий',
};

const styleDesktop: CSSProperties = {
  textAlign: 'center',
  marginBottom: '3.47vw',
  borderBottom: 'none',
};

const styleMobile: CSSProperties = {
  textAlign: 'left',
  marginBottom: '0',
  borderBottom: 'none',
};

const GetOn = () => {
  const [hoverButtonName, setHoverButtonName] = useState<string>('');
  const [buttonName, setButtonName] = useState<string | boolean>('');

  const getButton = (name: string, text: string) => {
    return (
      <WrapperButton>
        <Button
          name={name}
          onClick={(e: any) => setButtonName(e.target.name)}
          onMouseEnter={(e: any) => {
            setHoverButtonName(e.target.name);
          }}
          onMouseLeave={() => {
            setHoverButtonName('');
          }}
        >
          {text}
        </Button>
        <Overlay overlay={hoverButtonName === name} />
      </WrapperButton>
    );
  };

  return (
    <Layout
      titlePage={data.title}
      titlePageMobile={data.titleMobile}
      title={siteTitle.title}
      description={siteData.description}
      styleTitle={styleDesktop}
      styleTitleMobile={styleMobile}
    >
      {({ lang }: { lang: 'en' | 'ru' }) => (
        <Container>
          <Content>
            <p>{data.text[lang]}</p>
          </Content>
          <WrapperContent paddingBottom={buttonName === 'old'}>
            <Block show={false}>
              {buttonName === 'new' ? (
                <Form setButton={setButtonName} textTextarea={textTextarea} />
              ) : (
                getButton('new', data.become[lang])
              )}
            </Block>
            <div>
              <VerticalLine />
            </div>
            <Line show={buttonName !== 'new'} />
            <Block className={'partners'} show={buttonName === 'new'}>
              {buttonName === 'old' ? (
                <Partners />
              ) : (
                getButton('old', data.our[lang])
              )}
            </Block>
          </WrapperContent>
        </Container>
      )}
    </Layout>
  );
};

export default GetOn;

const Container = styled.div`
  padding-top: 3.47vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    padding: 15.63vw 0 0 0;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5.97vw;
  & > p {
    font-family: 'Atyp Display', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.53vw;
    line-height: 1.88vw;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    width: 31.53vw;
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    margin-bottom: 15.63vw;
    & > p {
      font-size: 5.31vw;
      line-height: 7.81vw;
      width: 100%;
    }
  }
`;

const WrapperContent = styled.div<{ paddingBottom: boolean }>`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  .partners {
    button {
      border-radius: 0;
    }
    @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
      padding-bottom: ${({ paddingBottom }) =>
        !paddingBottom ? '15.63vw' : 0};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: grid;
    justify-content: inherit;
    align-items: inherit;
  }
`;
const Block = styled.div<{ show: boolean }>`
  width: 100%;
  padding-top: 6.94vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    padding-top: 0;
    display: ${({ show }) => (show ? 'none' : 'block')};
  }
`;

const WrapperButton = styled.div`
  width: 100%;
  height: 100%;
  padding: 15.63vw 0 12.64vw;
  cursor: pointer;
  position: relative;
  button {
    background: transparent;
    border-radius: 4.06vw;
    @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
      border-radius: 13.75vw;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    padding: 0;
    button {
      border: 0.63vw solid #071013;
      background: #baff49;
      width: 93.44vw;
      font-size: 6.88vw;
      line-height: 12.19vw;
    }
  }
`;
const Overlay = styled.div<{ overlay: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  background: ${({ overlay }) =>
    overlay
      ? 'linear-gradient(0deg, #baff49 0%, rgba(186, 255, 73, 0) 100%)'
      : 'transparent'};
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: none;
  }
`;

const Line = styled.div<{ show: boolean }>`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: ${({ show }) => (show ? 'grid' : 'none')};
    outline: 0.3vw solid #000000;
    margin: 15.63vw 3.13vw;
  }
`;
