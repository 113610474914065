import React from 'react';
import styled from 'styled-components/macro';
import { Layout } from '../layout';
import { useLayout } from '../layout/useLayout';

const siteTitle = {
  title: {
    en: 'HEAVEN - digital platform',
    ru: 'HEAVEN - цифровая платформа',
  },
};

const siteData = {
  description: {
    en:
      'Platform for quick access to digital solutions based on video analysis and artificial intelligence',
    ru:
      'Платформа для быстрого доступа к цифровым решениям на базе видеоанализа и искусственного интеллекта',
  },
};

type Data = Record<string, { en: string; ru: string }>;

const subText = [
  {
    en: 'During the process of creation of new solutions, at some point,',
    ru: 'Создавая новые решения, мы в какой-то момент осознали, что все они',
  },
  {
    en: 'we realized that all of them are interconnected,',
    ru:
      'связаны между собой, и каждое из них может стать частью целого, быть доступным для людей и компаний «по клику».',
  },

  {
    en:
      'and that each one can become part of a whole and be available for people and companies with just a click.',
    ru: '',
  },
];

const data: Data = {
  title: { en: 'Heaven', ru: 'Heaven' },
  titleMobile: {
    en: 'Hea&shy;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ven',
    ru: 'Hea&shy;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ven',
  },
  idea: {
    en: 'And then, we had an idea: develop a platform for digital products,',
    ru: 'И нам пришла идея – разработать платформу для цифровых продуктов,',
  },
  cloud: {
    en:
      'рdeploy it in a cloud and equip it with a quick and easy access to systems, cognitive algorithms and devices.',
    ru:
      'развернуть ее в облаке и предоставить простой и быстрый доступ к системам, когнитивным алгоритмам, устройствам.',
  },
  universe: {
    en:
      ' That’s how we created HEAVEN: a place on the Internet, a portal, a resource, the Universe in its singular state.',
    ru:
      ' Так появился HEAVEN – место в интернет, портал, ресурс, Вселенная в сингулярном состоянии.',
  },
  already: {
    en:
      'Our Big Bang is yet to come, but HEAVEN already provides the best innovative solutions based on the sacred AI, IoT, Computer Vision and AR.',
    ru:
      'Наш Большой взрыв впереди, но уже сейчас на HEAVEN вы найдете лучшие в своем роде инновационные решения на базе сакральных AI, IoT, Computer Vision, AR.',
  },
  programs: {
    en: 'HEAVEN provides an integrated environment for programs,',
    ru: 'Здесь программы, методы обработки данных, датчики и',
  },
  comp: {
    en: 'data processing methods,',
    ru: 'компьютеры «живут» в интегрированной среде,',
  },
  processing: {
    en: 'sensors and computers to co-exist',
    ru: 'взаимодействуя друг с',
  },
  together: { en: 'and interact in.', ru: 'другом.' },
  right: {
    en: 'The “',
    ru: 'В правом верхнем углу страницы есть заветная кнопка «',
  },
  we: { en: 'Find us on Heaven', ru: 'Мы на HEAVEN' },
  click: {
    en:
      '” button you’re searching for is in the upper right corner of the page. It will take you to the world of smart developments and off-the-shelf solutions by ',
    ru:
      '». Нажав на нее, вы попадете в мир умных разработок и готовых решений ',
  },
  sky: { en: 'SKYTRACK', ru: 'SKYTRACK' },
  slogan: { en: 'SKYTRACK is HEAVEN', ru: 'SKYTRACK — это HEAVEN.' },
  mobileText: {
    en:
      'Our Big Bang is yet to come, but HEAVEN already provides the best innovative solutions based on the sacred AI, IoT, Computer Vision and AR. HEAVEN provides an integrated environment for programs, data processing methods, sensors and computers to co-exist and interact in.',
    ru:
      'Наш Большой взрыв впереди, но уже сейчас на HEAVEN вы найдете лучшие в своем роде инновационные решения на базе сакральных AI, IoT, Computer Vision, AR. Здесь программы, методы обработки данных, датчики и компьютеры «живут» в интегрированной среде, взаимодействуя друг с другом.',
  },
  mobileContent: { en: '”', ru: '».' },
  mobile: {
    en:
      'button you’re searching for is in the upper right corner of the page. It will take you to the world of smart developments and off-the-shelf solutions by',
    ru: 'Нажав на нее, вы попадете в мир умных разработок и готовых решений',
  },
};

const SubTitle = () => {
  const { lang } = useLayout();
  return (
    <WrapperSubTitle>
      <div className={'subText'}>{subText[0][lang]}</div>
      <div className={'mobileSubText'}>{subText[1][lang]}</div>
      <div className={'subText'}>{subText[2][lang]}</div>
    </WrapperSubTitle>
  );
};

const Heaven = () => {
  return (
    <Layout
      titlePage={data.title}
      titlePageMobile={data.titleMobile}
      title={siteTitle.title}
      description={siteData.description}
      rendererSubTitle={() => <SubTitle />}
    >
      {({ lang }: { lang: 'en' | 'ru' }) => (
        <Container>
          <ContentHeaven>
            <div className={'subTextContent'}>
              <div style={{ textIndent: '11.5em' }}>{data.idea[lang]}</div>
              <div style={{ marginBottom: '1.74vw', width: '70vw' }}>
                {data.cloud[lang]}
              </div>
              <div style={{ marginBottom: '3.13vw' }}>
                {data.universe[lang]}
              </div>
              <div>{data.already[lang]}</div>
              <div>{data.programs[lang]}</div>
              <div>{data.comp[lang]}</div>
              <div>{data.processing[lang]}</div>
              <div>{data.together[lang]}</div>
            </div>
            <div className={'mobileSubTextContent'}>
              <div>
                {data.idea[lang]} {data.cloud[lang]}
              </div>
              <div>{data.universe[lang]}</div>
              <div>{data.mobileText[lang]}</div>
              <div>
                {data.right[lang]}
                <span style={{ color: '#FF2ECC' }}>{data.we[lang]}</span>
                {data.mobileContent[lang]}
              </div>
              <div>
                {data.mobile[lang]}{' '}
                <span style={{ color: '#BAFF49' }}>{data.sky[lang]}</span>.
              </div>
            </div>
          </ContentHeaven>
          <Content>
            {data.right[lang]}
            <span style={{ color: '#FF2ECC' }}>{data.we[lang]}</span>
            {data.click[lang]}
            <span style={{ color: '#BAFF49' }}>{data.sky[lang]}</span>.
          </Content>
          <Slogan>{data.slogan[lang]}</Slogan>
        </Container>
      )}
    </Layout>
  );
};
export default Heaven;

const Container = styled.div`
  padding: 6.18vw 1.39vw 0 1.81vw;
  font-family: 'Atyp Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.53vw;
  line-height: 1.88vw;
  text-transform: uppercase;
  color: #071013;
  @media screen and (max-width: ${({ theme }) => theme.table_smallest}) {
    padding-left: max(1.4vw, env(safe-area-inset-left));
    padding-right: max(1.4vw, env(safe-area-inset-right));
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 5.63vw;
    line-height: 8.13vw;
    text-transform: none;
    padding: 12.5vw 3.13vw 0;
  }
`;

const ContentHeaven = styled.div`
  width: 73.57vw;
  margin-bottom: 3.26vw;

  .subTextContent {
    display: grid;
  }

  .mobileSubTextContent {
    display: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    width: 100%;
    .subTextContent {
      display: none;
    }

    .mobileSubTextContent {
      display: grid;

      & > div {
        margin-bottom: 15vw;
      }
    }
  }
`;

const Content = styled.div`
  width: 68.47vw;
  margin-left: auto;
  text-align: right;
  margin-bottom: 4.58vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: none;
  }
`;

const Slogan = styled.div`
  font-size: 4.31vw;
  line-height: 4.31vw;
  align-items: center;
  letter-spacing: -0.04em;
  text-transform: none;
  display: flex;
  justify-content: center;
  margin-bottom: 4.58vw;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 9.69vw;
    line-height: 12.19vw;
    text-align: right;
    text-transform: uppercase;
    margin-bottom: 12.5vw;
  }
`;

const WrapperSubTitle = styled.div`
  width: 67.74vw;
  margin-left: auto;
  margin-bottom: 5.63vw;

  .subText {
    text-indent: 11em;
  }

  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    margin: 5vw 0;
    width: 100%;
    .subText {
      text-indent: 0;
    }

    .mobileSubText {
      text-indent: 7.5em;
      margin-top: 5vw;
    }
  }
`;
