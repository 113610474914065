import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Layout } from '../layout';
import { navigate, useLocation } from '@reach/router';
import queryString, { ParsedQuery } from 'query-string';
import { useLayout } from '../layout/useLayout';
import { isMobile } from 'react-device-detect';
import { VectorDown } from '@icons';

type Data = Record<string, { en: string; ru: string }>;

const data: Data = {
  title: { en: 'Formalities', ru: 'Формальности' },
  titleMobile: { en: 'Forma&shy;lities', ru: 'Фор&shy;мально&shy;сти' },
  privacy: { en: 'Privacy Policy', ru: 'Пользовательское соглашение' },
  user: {
    en: 'User Agreement',
    ru: 'Политика конфиденциальности',
  },
  agreement: {
    en: 'Cookie Policy',
    ru: 'Использование cookies',
  },
};

const siteTitle = {
  title: {
    en: 'Site rules',
    ru: 'Правила сайта',
  },
};

const siteData = {
  description: {
    en: 'Terms of use. Privacy Policy. Use of cookies',
    ru:
      'Пользовательское соглашение. Политика конфиденциальности. Использование файлов cookies',
  },
};

const text: Data = {
  1: {
    en:
      '    1. General Provisions\n' +
      '        1.1. This User Agreement sets out the terms of use of the Website, and it is concluded between any person using the Website and SKYTRACK LLC, INN (Taxpayer Identification Number) 7731467354, 123104, Russian Federation, Moskva,\n' +
        '\n' +
        'ul. Malaya Bronnaya, d. 2, building 1, (the "Website Administration").  \n' +
      '        1.2. By using the Website in any way, the User expresses their full and unconditional consent to all terms of this User Agreement.   \n' +
      '        1.3. The Website can only be used under the terms of this User Agreement. If the User does not accept the terms of the User Agreement in full, the User does not have the right to use the Website for any purpose. It is prohibited to use the Website in violation of (failure to comply with) any of the terms of this User Agreement.  \n' +
      '    2. The Rights to the Website  \n' +
      '        2.1. The rights to the Website belong to Skytrack LLC.    \n' +
      "    3. THE USER'S OBLIGATIONS \n" +
      "        3.1. The User agrees not to take actions and not to leave comments and entries that may be considered as violating the current legislation of the Russian Federation or international law, including intellectual property rights, copyright and/or related rights, generally accepted moral norms and ethics, as well as any actions that lead or may lead to disruption of the Website's normal operation.    \n" +
      "        3.2. Use of Website materials without the right holder's consent is not allowed. \n" +
      '        3.3. It is necessary to provide a link to the Website when quoting Website materials. \n' +
      '        3.4. The Website Administration does not bear any responsibility for the User visiting and using external resources links to which may be contained on the Website. \n' +
      '        3.5. The Website Administration does not bear any responsibility and has no direct or indirect obligations to the User in connection with any possible or incurred losses or damages associated with any content of the Website, copyright registration and information about such registration, goods and services available or received through external websites or resources or other contacts the User has had using the information posted on the Website or links to external resources. \n' +
      '        3.6. The User agrees that the Website Administration does not bear any responsibility and does not have any obligations in connection with advertising that may be placed on the Website. \n' +
      '    4. Use Restrictions\n' +
      '        4.1. The User does not have the right to extract any graphic, informational, sound, photo, video and other materials included in the Website content and subsequently use them in any form and in any way.\n' +
      '        4.2. It is prohibited to copy, reproduce, process, distribute, make freely available (publish) on the Internet, use in mass media and/or use for commercial purposes both materials directly extracted from the Website and obtained by copying the results of data processing using the Website, as well as products derived from such materials (with additions, reductions and other edits), except for cases expressly provided for in this User Agreement.  \n' +
      '    5. Responsibility \n' +
      '        5.1. The Website is provided on an "as is" basis. The Website Administration does not provide any guarantees regarding the error-free and uninterrupted operation of the Website or its individual components and/or functions, the Website\'s compliance with the User\'s specific goals and expectations. The Website Administration does not guarantee reliability, accuracy, completeness and timeliness of the data, and it does not provide any other guarantees except for those expressly specified in this User Agreement.  \n' +
      '        5.2. The Website Administration is not responsible for any direct (actual damage) or indirect consequences, including lost profits, any use or inability to use the Website and/or damage caused to the User and/or third parties as a result of any use, non-use or the inability to use the Website or its individual components and/or functions, including due to possible errors or failures in the Website operation, except for those expressly provided for by the legislation of the Russian Federation. \n' +
      '        5.3. All information and/or advertising materials posted on the Website provided by third parties; the Website Administration is not responsible for the accuracy of information and/or advertising materials of third parties, the availability of their websites and their content, as well as for any consequences associated with the use information and/or advertising, as well as websites of third parties. The Website Administration has the right to display any informational and/or advertising materials in any places of the Website at its discretion.\n' +
      '        5.4. The User is responsible for any violation of the obligations established by this User Agreement and/or the applicable law, as well as for all the consequences of such violations (including any losses that may be incurred by the Website Administration and other third parties).  \n' +
      '        5.5. The Website Administration reserves the right to prosecute violators of exclusive rights to intellectual property in accordance with civil, administrative and criminal law at its discretion.\n' +
      '    6. Final Provisions\n' +
      "        6.1. This User Agreement and all relations associated with the use of the Website shall be subject to the law of the Russian Federation, and any claims or lawsuits arising from this User Agreement or the use of the Website must be filed and considered in court at the Website Administration's location.  \n" +
      '        6.2. If any provision of this User Agreement is recognized by the court as invalid or unenforceable, it does not entail the invalidity of other provisions of the User Agreement. \n' +
      '        6.3. Inaction on the part of the Website Administration in case of violation of the provisions of the User Agreement by any of the Users does not deprive the Website Administration of the right to later take appropriate actions to protect its interests and copyright to the Website materials protected in accordance with the law. \n' +
      '        6.4. By using the Website, the User confirms that they accept the terms of this User Agreement, as well as the Privacy Policy. All data on the use of the Website transmitted in accordance with this User Agreement are stored and processed in accordance with the Privacy Policy, which is an integral part of this User Agreement. The Privacy Policy can be found at: https://skytracking.ru/formalities?page=2\n' +
      '        6.5. The Website Administration may provide the User with a translation of this User Agreement from Russian into other languages; however, in the event of a discrepancy between the terms of the User Agreement in Russian and a translation, only the Russian version of the User Agreement shall be legally binding.   \n' +
      '        6.6. This User Agreement may be unilaterally changed by the Website Administration. The Users are notified about the changes made to the terms of this User Agreement in the form of an updated version of the User Agreement being published. The specified changes to the terms of the User Agreement come into force on the date of their publication (update), unless otherwise specified in the relevant publication. \n' +
      "        6.7. All questions and claims related to the use/inability to use the Website or data, as well as those related to possible violations of the law and/or third parties' rights by the Website or data should be addressed to us by phone: +7 499 322 77 72 or by e-mail: office@skytracking.ru.     \n",
    ru: '1.Общие положения\n' +
        '\t1.1 Настоящее Пользовательское соглашение устанавливает условия использования Сайта и заключено между любым лицом, использующим Сайт, и ООО «СКАЙТРЭК» (SKYTRACK LLC), ИНН 7731467354, 123104, Российская Федерация, г. Москва, ул. Малая Бронная, дом 2, стр. 1, этаж 3, пом. I, ком. 15 (далее – «Администрация сайта»).  \n' +
        '\t1.2 Используя Сайт любым образом, Пользователь выражает свое полное и безоговорочное согласие со всеми условиями настоящего Пользовательского соглашения.   \n' +
        '\t1.3\tИспользование Сайта разрешается только на условиях настоящего Пользовательского соглашения. Если Пользователь не принимает условия настоящего Пользовательского соглашения в полном объеме, Пользователь не имеет права использовать Сайт в каких-либо целях. Использование Сайта с нарушением (невыполнением) какого-либо из условий настоящего Пользовательского соглашения запрещено.  \n' +
        '2. Права на САЙТ  \n' +
        '\t2.1\tПрава на Сайт принадлежат ООО «Скайтрэк».    \n' +
        '3. ОБЯЗАТЕЛЬСТВА ПОЛЬЗОВАТЕЛЯ \n' +
        '\t3.1\tПользователь соглашается не предпринимать действий и не оставлять комментарии и записи, которые могут рассматриваться как нарушающие действующее законодательство Российской Федерации или нормы международного права, в том числе в сфере интеллектуальной собственности, авторских и/или смежных прав, общепринятые нормы морали и нравственности, а также любых действий, которые приводят или могут привести к нарушению нормальной работы Сайта.    \n' +
        '\t3.2\tИспользование материалов Сайта без согласия правообладателя не допускается. \n' +
        '\t3.3\tПри цитировании материалов Сайта ссылка на Сайт обязательна. \n' +
        '\t3.4\tАдминистрация Сайта не несет ответственности за посещение и использование Пользователем внешних ресурсов, ссылки на которые могут содержаться на Сайте. \n' +
        '\t3.5\tАдминистрация Сайта не несет ответственности и не имеет прямых или косвенных обязательств перед Пользователем в связи с любыми возможными возникшими потерями или убытками, связанные с любым содержанием Сайта, регистрацией авторских прав и сведениями о такой регистрации, товарами и услугами, доступными или полученными через внешние сайты или ресурсы либо иные контакты Пользователя, в которые он вступил, используя размещенную на Сайте информацию или ссылки на внешние ресурсы. \n' +
        '\t3.6\tПользователь согласен с тем, что Администрация Сайта не несет какой-либо ответственности и не имеет каких-либо обязательств в связи с рекламой, которая может быть размещена на Сайте. \n' +
        '4. Ограничения использования\n' +
        '\t4.1\tПользователь не имеет права извлекать любые графические, информационные, звуковые, фото, видео и прочие материалы, включаемые в содержание Сайта, и осуществлять их последующее использование в любой форме и любым способом.  \n' +
        '\t4.2\tЗапрещается копирование, воспроизведение, переработка, распространение, размещение в свободном доступе (опубликование) в сети Интернет, использование в средствах массовой информации и/или коммерческое использование материалов, как прямо извлеченных из Сайта, так и полученных путем копирования результатов обработки данных с использованием Сайта, а также производных от таких материалов продуктов (с дополнениями, сокращениями и прочими переработками), за исключением случаев, прямо предусмотренных настоящим Пользовательским соглашением.  \n' +
        '5. Ответственность \n' +
        '\t5.1\tСайт предоставляется на условиях «как есть» (as is). Администрация сайта не представляет никаких гарантий в отношении безошибочной и бесперебойной работы Сайта или отдельных ее компонентов и/или функций, соответствия Сайта конкретным целям и ожиданиям Пользователя, не гарантирует достоверность, точность, полноту и своевременность данных, а также не предоставляет никаких иных гарантий, прямо не указанных в настоящем Пользовательском соглашении.  \n' +
        '\t5.2\tАдминистрация сайта не несет ответственности за какие-либо прямые (реальный ущерб) или косвенные последствия, включая упущенную выгоду, какого-либо использования или невозможности использования Сайта и/или ущерб, причиненный Пользователю и/или третьим сторонам в результате какого-либо использования, неиспользования или невозможности использования Сайта или отдельных ее компонентов и/или функций, в том числе из за возможных ошибок или сбоев в работе Сайта, за исключением случаев, прямо предусмотренных законодательством Российской Федерации. \n' +
        '\t5.3\tАдминистрация сайта не несет ответственности за все информационные и/или рекламные материалы, размещаемые на Сайте, предоставленными третьими лицами. Администрация сайта не несет ответственности за достоверность информационных и/или рекламных материалов третьих лиц, доступность их интернет-сайтов и их содержание, а также за любые последствия, связанные с использованием информации и/или рекламы, а также интернет-сайтов третьих лиц. На Сайте Администрация сайта вправе отображать любые информационные и/или рекламные материалы в любых местах по своему усмотрению.\n' +
        '\t5.4\tПользователь несет ответственность за любое нарушение обязательств, установленных настоящим Пользовательским соглашением и/или применимым правом, а также за все последствия таких нарушений (включая любые убытки, которые может понести Администрация сайта и иные третьи лица).  \n' +
        '\t5.5\tАдминистрация сайта оставляет за собой право преследования нарушителей исключительных прав на объекты интеллектуальной собственности в соответствии с гражданским, административным и уголовным законодательством по своему усмотрению.\n' +
        '6. Заключительные положения\n' +
        '\t6.1\tК настоящему Пользовательскому соглашению и всем отношениям, связанным с использованием Сайта, подлежит применению право Российской Федерации и любые претензии или иски, вытекающие из настоящего Пользовательского соглашения или использования Сайта, должны быть поданы и рассмотрены в суде по месту нахождения Администрации сайта.  \n' +
        '\t6.2\tПризнание судом какого-либо положения настоящего Пользовательского соглашения недействительным или не подлежащим принудительному исполнению не влечет недействительности иных положений Пользовательского соглашения. \n' +
        '\t6.3\tБездействие со стороны Администрации сайта в случае нарушения кем-либо из Пользователей положений Пользовательского соглашения не лишает Администрацию сайта права предпринять позднее соответствующие действия в защиту своих интересов и защиту авторских прав на охраняемые в соответствии с законодательством материалы Сайта. \n' +
        '\t6.4\tИспользуя Сайт, Пользователь подтверждает, что принимает условия настоящего Пользовательского соглашения, а также Политики конфиденциальности. Все данные об использовании Сайта, передаваемые в соответствии с настоящим Пользовательским соглашением, сохраняются и обрабатываются в соответствии с Политикой конфиденциальности, являющейся неотъемлемой частью настоящего Пользовательского соглашения и размещенной на странице по адресу: https://skytracking.ru/formalities?page=2 \n' +
        '\t6.5\tАдминистрация сайта может предоставить Пользователю перевод настоящего Пользовательского соглашения с русского на другие языки, однако в случае противоречия между условиями Пользовательского соглашения на русском языке и ее переводом, юридическую силу имеет исключительно русскоязычная версия Пользовательского соглашения.   \n' +
        '\t6.6\tНастоящее Пользовательское соглашение может изменяться Администрацией сайта в одностороннем порядке. Уведомление Пользователя о внесенных изменениях в условия настоящего Пользовательского соглашения публикуются в виде обновленной версией Пользовательского соглашения. Указанные изменения в условия Пользовательского соглашения вступают в силу с даты их публикации (обновления), если иное не будет оговорено в соответствующей публикации. \n' +
        '\t6.7\tВсе вопросы и претензии, связанные с использованием/невозможностью использования Сайта или данных, а также возможным нарушением Сайта или данными законодательства и/или прав третьих лиц, должны направляться по телефону: +7 499 322 77 72, или по электронной почте: office@skytracking.ru.     '
  },
  2: {
    en:
      '1. General Provisions\n' +
      '1.1. This Privacy Policy (the "Policy") applies to all information, including personal data as defined by the applicable legislation ("Personal Information"), which Limited Liability Company Skytrack (INN (Taxpayer Identification Number) 7731467354, OGRN (Primary State Registration Number) 1147746265017, address: \n' +
        '\n' +
        'ul. Malaya Bronnaya, d. 2, building 1, Moscow, Russia, 121205) (the "Company") may obtain about an Internet user (the "User") while they are using the website www.skytracking.ru (the "Website"). \n' +
      "1.2. The Policy applies exclusively to the Company's Website. The Company does not control and bears no responsibility for the websites and services of third parties that the User can access via links available on the Company's Website; the Company bears no responsibility for information about the User processed by third parties. \n" +
      "1.3. The User needs to study the terms of the Policy before using the Website. The use of the Website is only permitted upon unconditional consent to all the terms of the Policy. By using the Website, the User unconditionally consents to the terms of the Policy, including the terms of provision and processing of the User's personal data. The User must refrain from using the Website in case they disagree with the terms of the Policy or do not understand the Policy. \n" +
      '1.4. The Company reserves the right to make unilateral changes to the Policy at any time without prior notice to the User. Changes made to the Policy come into force on the date the new version of the Policy is posted on the Website. \n' +
      "2. User's personal data received and processed by the company\n" +
      '2.1. Within the Policy, "User\'s personal data" means: \n' +
      '2.1.1. Personal data that the User provides about themselves independently when filling out a partnership application and/or an application for acquiring additional information from the Company: \n' +
      "- the User's last name, first name and patronymic, \n" +
      "- the company on behalf of which the User is applying for a partnership, and the User's\n" +
      'position in such company, \n' +
      "- the User's contact phone number,\n" +
      "- the User's e-mail address.\n" +
      "2.1.2. Data that is automatically transmitted to the Company in the process of using the Website using the software installed on the User's device, including IP address, information from Cookies (text files stored in the User's browser), information about the User's browser (or other program, with which the user accesses the Website, time of access and address of the requested page, including: \n" +
      "2.1.2.1. When the User is browsing the Company's Website, depersonalized statistical data about them are automatically collected (from Cookies), including the following: \n" +
      '• type of action performed on the Website (click, hover, etc.); \n' +
      '• date and time at which the action was executed; \n' +
      '• page URL; \n' +
      '• Referer; \n' +
      '• IP (without the ability to work with IP addresses in statistics); \n' +
      '• User-Agent; \n' +
      '• ClientID (cookie file-based browser identifier);  \n' +
      '• screen resolution; \n' +
      '• the class of the HTML element that is being clicked; \n' +
      '• data about the information viewed by the User in the interface of the Website; \n' +
      '• data on filling out forms/applications on the Website, including data concerning errors when filling them out. \n' +
      '2.1.2.2. By using the Website, the User agrees that the Company may use statistical data and Cookies for their subsequent processing by various systems, for example, but not limited to, Google Analytics, Google Firebase, Appmetrica, and may transfer them to a third party for research, performance of work or provision of services to the Company. The user can independently manage Cookies by changing the settings of the browser that operates on their equipment. Changes to user settings that result in blocking cookies may result in the unavailability of certain components of the Website. \n' +
      "2.2. The Company does not verify the accuracy of personal information provided by the User during their use of the Website and does not monitor the User's legal capacity. At the same time, the Company assumes that the User provides accurate and sufficient personal information about themselves and maintains such information up to date. \n" +
      "3. Purposes of Collecting and Processing the User's Personal Data\n" +
      "3.1. The Company only collects and stores User's personal data that are necessary to enable the use of the Company's Website or the execution of agreements with the User, except for cases when the law provides for mandatory receipt and storage of certain personal data of the User. The personal data is stored for no longer than required for their processing, except for cases when the storage period for such personal data of the User is not established by law and/or an agreement between the User and the Company. \n" +
      "3.2. The Company uses the User's personal data for the following purposes: \n" +
      "3.2.1. providing the User with the opportunity to use the Company's Website and perform actions provided for by the Website's functionality; \n" +
      "3.2.2. identifying the User who filled out a partnership application, establishing communication with the User, including sending notifications, requests regarding establishing a partnership with the company on behalf of which the User submitted the partnership application, providing the User with information related to the Company's products;\n" +
      "3.2.3. communicating with the User, including sending notifications, requests and information regarding the use of the Website, as well as processing the User's requests and applications; \n" +
      "3.2.4. improving the quality of the Company's Website and the convenience of its use; \n" +
      '3.2.5. conducting statistical research and other research based on depersonalized data.\n' +
      '4. Procedure for Processing Personal Data and Transferring Them to Third Parties\n' +
      '4.1. The storage and processing of the User\'s personal data is carried out in accordance with Federal Law No. 152-ФЗ "On personal data", dated July 27, 2006, and other regulations governing relations in the field of ensuring personal data security. Storage and processing of personal data is carried out within the Russian Federation.\n' +
      "4.2. The Company processes the User's personal data in accordance with the Policy and the Company's internal documents. In accordance with the requirements of the law, the Company may be required to process/store the User's personal data obtained when using the Website. Such processing/storage is carried out by the Company in cases, on the grounds and within the time limits established by the legislation of the Russian Federation. \n" +
      "4.3. The Company ensures confidentiality the User's personal data. \n" +
      "4.4. The Company has the right to share the User's personal data with third parties in the following cases: \n" +
      '4.4.1. The User has expressed their consent to the performance of such actions by the Company;\n' +
      "4.4.2. The transfer takes place as part of the sale or other transfer of the business (in whole or in part), while the acquirer receives all obligations to comply with the terms of the Policy in relation to the User's personal data received by them. \n" +
      '4.4.3. The data transfer in compliance with the procedure established by law is provided for by Russian or other applicable law. \n' +
      '4.4.4. In order to ensure the possibility of protecting the rights and legitimate interests of the Company or third parties in cases when the Company has reasonable grounds to believe that the User violates the requirements of the applicable law. \n' +
      "4.3. The Company does not place Personal Data on public resources on the Internet since such placement does not meet the purposes of collecting and processing Personal Data. Under no circumstances shall the Company be liable for the consequences of the User's independent provision of Personal Data about themselves and (or) other individuals for public access to an unlimited number of persons, including by posting this information on public resources on the Internet, including on the publicly available pages of the Website.\n" +
      "5. Change and Deletion of the User's Personal Data and Providing Access to Them\n" +
      "5.1. Change and deletion of the User's personal data \n" +
      "5.1.1. To the extent established by the applicable law, the User has the right to withdraw any of their consent that they had previously given or to lawfully submit their objections regarding the processing of their personal data. If the User wishes to delete their Personal Data from the Company's servers, they must send a corresponding request to the Company's email: office@skytracking.ru. The Company shall delete the Personal Data within 30 (calendar) after receiving the User's request.\n" +
      "5.1.2. The Company, within the limits established by the applicable law, shall inform each recipient to whom the User's personal information has been disclosed of the change or destruction of the User's personal data, except for cases when it is impossible or when it requires a disproportionate effort. \n" +
      "5.2. The Right to Access the User's Personal Data \n" +
      '5.2.1. In accordance with the applicable law, the User has the right to request information from the Company regarding:  \n' +
      "• purposes of processing the User's personal data; \n" +
      "• categories of the User's personal data that is processed;  \n" +
      "• categories of recipients to whom the User's personal data have been or will be transferred; \n" +
      "• storage period or criteria for its determination, as well as other information regarding the User's personal data. \n" +
      "5.2.2. Unless otherwise implemented in the interface of the Company's Website, the information (its copy) specified in clause 5.2.1 of the Policy may be provided by the Company in writing or using other means of communication. \n" +
      '5.2.3. If the Company has reason to doubt the identity of the User submitting a request in accordance with clause 5.2.1 of the Policy, the Company has the right to request the provision of additional information necessary to confirm the identity of such User. \n' +
      '5.2.4. Consideration of a request for the provision of information (a copy thereof) specified in clause 5.2.1 of the Policy is carried out by the Company within 30 (thirty) calendar days from the date of receipt of the request from the User. The Company has the right to extend the specified period up to 60 (sixty) calendar days, taking into account the complexity and number of requests. \n' +
      "5.2.5. In case the User's request to provide information specified in clause 5.2.1 of the Policy does not have a clear justification, as well as in case of an excessive number of requests, the Company has the right to charge a reasonable fee for fulfilling the request (including administrative costs) or refuse to fulfill it \n" +
      "6. Measures Taken to Protect the User's Personal Data\n" +
      '6.1. The Company protects and processes the User\'s personal data in accordance with the requirements of the applicable law, including Federal Law No. 152-ФЗ "On personal data" dated July 27, 2006, Decree of the Government of the Russian Federation No. 1119 "On approval of the requirements for the protection of personal data during their processing in personal data informational systems" dated November 1, 2012, and other regulations governing relations in the field of ensuring personal data security.  \n' +
      "6.2. When processing personal data, the Company ensures their security and takes the necessary organizational and technical measures to protect the User's personal data from unauthorized or accidental access to them, their destruction, modification, blocking, copying, distribution of the User's personal data, as well as from other illegal actions by keeping such data confidential and ensuring that it remains confidential, as well as by introducing additional protection measures that implement the requirements of the legislation of the Russian Federation, standards and internal organizational and administrative documents of the Company. At the same time, the User understands and agrees with the fact that it is impossible to ensure total protection of information on the Internet from Internet threats. The User hereby gives their unconditional and irrevocable consent to the Company determining an adequate level of protection of Personal Data and the methods and place (territory) of their storage, allowed in accordance with the applicable law.\n" +
      '6.3. Personal data are stored in the Russian Federation. \n' +
      '6.4. This Policy and the relationship between the User and the Company is governed by the current legislation of the Russian Federation.  \n' +
      "6.5. If the User becomes aware of any violation of the requirements of this Policy and (or) the legislation of the Russian Federation on personal data in relation to Personal Data (including, without limitation, the rules for collecting, processing, transferring, storing, distributing and destroying Personal Data), the User shall notify the Company of this by sending an email to the Company's email address office@skytracking.ru or by phone: +7 499 322 77 72.    \n",
    ru: '1. Общие положения\n' +
        '1.1. Настоящая Политика конфиденциальности (далее – «Политика») действует в отношении всей информации, включая персональные данные в понимании применимого законодательства (далее – «Персональная информация»), которую Общество с ограниченной ответственностью «Скайтрэк» (ИНН 7731467354, ОГРН 1147746265017, адрес: Россия, 123104, г. Москва, ул. Малая Бронная, дом 2, стр. 1, этаж 3, пом. I, ком. 15) (далее — «Общество») может получить о пользователе сети Интернет (далее — «Пользователь») во время использования им сайта www.skytracking.ru (далее – «Сайт»). \n' +
        '1.2. Политика применяется исключительно к Сайту Общества. Общество не контролирует и не несет ответственность за интернет-сайты и сервисы третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте Общества, в том числе за обрабатываемую третьими лицами информацию о Пользователе. \n' +
        '1.3. Перед началом использования Сайта, Пользователю необходимо изучить условия Политики. Использование Сайта возможно только при безусловном согласии со всеми условиями Политики. Использование Сайта означает безоговорочное согласие Пользователя с условиями Политики, в том числе с условиями предоставления и обработки персональных данных Пользователя. При несогласии с условиями Политики, либо при непонимании Политики, Пользователь должен воздержаться от использования Сайта. \n' +
        '1.4. Общество оставляет за собой право в любое время вносить в одностороннем порядке изменения в Политику без предварительного уведомления Пользователя. Изменения, внесенные в Политику, вступают в силу с даты размещения новой редакции Политики на Сайте. \n' +
        '2. Получаемые и обрабатываемые Обществом персональные данные Пользователя\n' +
        '2.1. В рамках Политики под «персональными данными Пользователя» понимаются: \n' +
        '2.1.1. Персональные данные, которые Пользователь предоставляет о себе самостоятельно при заполнении заявки на партнерство и/или заявки на получение дополнительной информации от Общества: \n' +
        '- фамилию, имя, отчество Пользователя, \n' +
        '- компанию, от имени которой Пользователь подает заявку на партнерство, и должность\n' +
        'Пользователя в такой компании, \n' +
        '- контактный телефон Пользователя,\n' +
        '- адрес электронной почты (e-mail) Пользователя.\n' +
        '2.1.2. Данные, которые автоматически передаются Обществу в процессе использования Сайта с помощью программного обеспечения, установленного на устройстве Пользователя, в том числе IP-адрес, информация из Сookie (текстовые файлы, хранящиеся в браузере Пользователя), информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к Cайту, время доступа, адрес запрашиваемой страницы, в том числе: \n' +
        '2.1.2.1. При просмотре Сайта Общества, происходит автоматический сбор (из Сookies) следующих обезличенных статистических данных о Пользователе, в том числе: \n' +
        '• тип выполненного на Сайте действия (клик, наведение курсора и т.п.); \n' +
        '• дата и время выполнения действия; \n' +
        '• URL страницы; \n' +
        '• Referer; \n' +
        '• IP (без возможности работы с IP-адресами в статистике); \n' +
        '• User-Agent; \n' +
        '• ClientID (идентификатор браузера по файлу cookie);  \n' +
        '• экранное разрешение; \n' +
        '• класс HTML-элемента, на который происходит клик; \n' +
        '• данные об информации, просматриваемой Пользователем в интерфейсе Сайта; \n' +
        '• данные о фактах заполнения форм/обращений на Сайте, включая ошибки при их заполнении. \n' +
        '2.1.2.2. Пользуясь Сайтом, Пользователь соглашается на то, что Общество может использовать статистические данные и файлы Сookies, для их последующей обработки системами, например, но не ограничиваясь, Google Analytics, Google Firebase, Appmetrica и может передавать третьему лицу для проведения исследований, выполнения работ или оказания услуг Обществу. Пользователь может самостоятельно управлять файлами Cookies путем изменения настроек браузера, функционирующего на его оборудовании. Изменения пользовательских настроек, в результате которых файлы cookies будут заблокированы, могут привести к недоступности отдельных компонентов Сайта. \n' +
        '2.2. Общество не проверяет достоверность персональной информации, предоставляемой Пользователем при использовании Сайта, и не осуществляет контроль за дееспособностью Пользователя. При этом Общество исходит из того, что Пользователь предоставляет достоверную и достаточную персональную информацию о себе и поддерживает такую информацию в актуальном состоянии. \n' +
        '3. Цели сбора и обработки персональных данных Пользователя\n' +
        '3.1. Общество собирает и хранит только те персональные данные Пользователя, которые необходимы для предоставления возможности использования Сайта Общества или исполнения соглашений с Пользователем, за исключением случаев, когда законодательством предусмотрено обязательное получение и хранение определенных персональных данных Пользователя. Хранение персональных данных осуществляется не дольше, чем этого требуют цели обработки, за исключением случаев, когда срок хранения таких персональных данных Пользователя не установлен законодательством и/или соглашением между Пользователем и Обществом. \n' +
        '3.2. Общество использует персональные данные Пользователя в следующих целях: \n' +
        '3.2.1. предоставление Пользователю возможности использования Сайта Общества и совершения действий, предусмотренных функционалом Сайта; \n' +
        '3.2.2. идентификации Пользователя, заполнившего заявку на партнерство, установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся установления партнерства с компанией, от имени которой Пользователь подал заявку на партнерство, предоставления Пользователю информации, касающейся продуктов Общества;\n' +
        '3.2.3. коммуникаций и осуществление связи с Пользователем, в том числе направления уведомлений, запросов и информации, касающихся использования Сайта, а также обработки запросов и заявок от Пользователя; \n' +
        '3.2.4. улучшения качества Сайта Общества, удобства его использования; \n' +
        '3.2.5. проведения статистических и иных исследований на основе обезличенных данных.\n' +
        '4. Порядок обработки персональных данных и порядок передачи их третьим лицам\n' +
        '4.1. Хранение и обработка персональных данных Пользователя осуществляется в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» и другими нормативными актами, регулирующими отношения в сфере обеспечения безопасности персональных данных. Хранение и обработка персональных данных осуществляется в пределах Российской Федерации.\n' +
        '4.2. Общество обрабатывает персональные данные Пользователя в соответствии с Политикой и внутренними документами Общества. В соответствии с требованиями законодательства на Общество может быть возложена обязанность осуществлять обработку/хранение персональных данных Пользователя, полученных при использовании Сайта. Такая обработка/хранение осуществляется Обществом в случаях, по основаниям и в течение сроков, установленных законодательством Российской Федерации. \n' +
        '4.3. В отношении персональных данных Пользователя, Общество обеспечивает их конфиденциальность. \n' +
        '4.4. Общество вправе передать персональные данные Пользователя третьим лицам в следующих случаях: \n' +
        '4.4.1. Пользователь выразил свое согласие на совершение Обществом таких действий;\n' +
        '4.4.2. Передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий Политики применительно к полученным им персональным данным Пользователя. \n' +
        '4.4.3. Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры. \n' +
        '4.4.4. В целях обеспечения возможности защиты прав и законных интересов Общества или третьих лиц в случаях, когда у Общества имеются достаточные основания полагать, что Пользователь нарушает требования применимого законодательства. \n' +
        '4.3. Общество не осуществляет размещение Персональных данных на общедоступных ресурсах в сети Интернет, поскольку такое размещение не отвечает целям сбора и обработки Персональных данных. Ни при каких обстоятельствах Общество не будет нести ответственность за последствия самостоятельного предоставления Пользователем Персональных данных о себе и (или) иных физических лицах для общего доступа неограниченному кругу лиц, в том числе путем размещения данной информации на общедоступных ресурсах в сети Интернет, в том числе, на общедоступных страницах Сайта.\n' +
        '5. Изменение и удаление персональных данных Пользователя, а также предоставление доступа к ним\n' +
        '5.1. Изменение и удаление персональных данных Пользователя \n' +
        '5.1.1. В пределах, установленных применимым законодательством, Пользователь вправе отозвать любое свое согласие, которое им было ранее предоставлено, или представить свои возражения на законных основаниях в отношении обработки его персональных данных. В случае, если Пользователь желает удалить Персональные данные с серверов Общества, он должен направить соответствующий запрос Обществу путем направления электронного письма на адрес электронной почты office@skytracking.ru. Общество осуществляет удаление Персональных данных в течение 30 (календарных) дней с момента получения запроса Пользователя.\n' +
        '5.1.2. Общество, в пределах, установленных применимым законодательством, сообщает об изменении или уничтожении персональных данных Пользователя каждому получателю, которому была раскрыта такая персональная информация, кроме случаев, когда это оказывается невозможным или требует несоразмерного усилия. \n' +
        '5.2. Право на доступ к персональным данным Пользователя \n' +
        '5.2.1. В соответствии с применимым законодательством, Пользователь вправе запросить у Общества информацию относительно:  \n' +
        '• целей обработки персональных данных Пользователя; \n' +
        '• категорий обрабатываемых персональных данных Пользователя;  \n' +
        '• категорий получателей, которым были или будут переданы персональные данные Пользователя; \n' +
        ' • срока хранения или критериев его определения, а также иных сведений относительно персональных данных Пользователя. \n' +
        '5.2.2. Если иное не реализовано в интерфейсе Сайта Общества, информация (ее копия), указанная в п.5.2.1 Политики может предоставляться Обществом в письменной форме или при помощи иных средств связи. \n' +
        '5.2.3. Если Общество имеет основания для сомнения относительно установления личности Пользователя, подающего запрос согласно п. 5.2.1 Политики, Общество вправе затребовать предоставления дополнительной информации, необходимой для подтверждения личности такого Пользователя. \n' +
        '5.2.4. Рассмотрение запроса о предоставлении сведений (их копии), указанных в п.5.2.1 Политики, осуществляется Обществом в течение 30 (тридцати) календарных дней с момента поступления запроса от Пользователя. Общество вправе продлить указанный срок до 60 (шестидесяти) календарных дней с учетом сложности и количества запросов. \n' +
        '5.2.5. В случае если запрос Пользователя о предоставлении информации, указанной в п.5.2.1 Политики, не имеет явного обоснования, а также в случае чрезмерного количества запросов, Общество вправе взимать разумную плату за выполнение запроса (с учетом административных расходов) или отказаться от его выполнения \n' +
        '6. Меры, применяемые для защиты персональных данных Пользователя\n' +
        '6.1. Общество производит защиту и обработку персональных данных Пользователя в соответствии с требованиями применимого законодательства, в том числе Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», Постановления Правительства РФ «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных» № 1119 от 01.11.2012 и других нормативных актов, регулирующих отношения в сфере обеспечения безопасности персональных данных.  \n' +
        '6.2. При обработке персональных данных Общество обеспечивает их безопасность и принимает необходимые организационные и технические меры для защиты персональных данных Пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения персональных данных Пользователя, а также от иных неправомерных действий, путем установления в отношении таких данных режима конфиденциальности и контроля за его соблюдением, а также путем внедрения дополнительных мер защиты, реализующих требования законодательства Российской Федерации, стандартов и внутренних организационно-распорядительных документов Общества. При этом Пользователь понимает и соглашается, что в сети интернет не может быть обеспечена абсолютная защита информации от угроз, которые в ней существуют. Настоящим Пользователь дает свое безусловное и безотзывное согласие Обществу определять достаточный уровень защиты Персональных данных, способы и место (территорию) их хранения, допускаемые в соответствии с применимым законодательством.\n' +
        '6.3. Персональные данные хранятся в Российской Федерации. \n' +
        '6.4. К настоящей Политике и отношениям между Пользователем и Обществом применяется действующее законодательство Российской Федерации.  \n' +
        '6.5. Если Пользователю стало известно о любом нарушении требований настоящей Политики и (или) законодательства Российской Федерации о персональных данных в отношении Персональных данных (в том числе, без ограничений, правил сбора, обработки, передачи, хранения, распространения, уничтожения Персональных данных), Пользователь должен уведомить об этом Общество путем направления электронного письма на адрес электронной почты Общества office@skytracking.ru или по телефону: +7 499 322 77 72.   ',},
  3: {
    en:
      "Cookies transmitted to the Website Administration by the User's technical device can be used to provide the User with personalized functions of the Website, for personalized advertising that is shown to the User, for statistical and research purposes, and for the purposes of improving the Website.\n",
    ru:
      'ООО «Скайтрэк» использует cookie (файлы с данными о прошлых посещениях сайта) для персонализации сервисов и удобства пользователей. Скайтрэк серьезно относится к защите персональных данных — ознакомьтесь с условиями и принципами их обработки.\n' +
      'Вы можете запретить сохранение cookie в настройках своего браузера или покинуть сайт, если не согласны с Политикой конфиденциальности.',
  },
};

const buttons = (lang: 'en' | 'ru') => [
  { id: 1, label: data.privacy[lang], content: text[1][lang] },
  { id: 2, label: data.user[lang], content: text[2][lang] },
  { id: 3, label: data.agreement[lang], content: text[3][lang] },
];

const Formalities = () => {
  const [content, setContent] = useState<string | boolean>('');
  const { lang } = useLayout();
  const [query, setQuery] = useState<ParsedQuery>();
  const location = useLocation();
  const [activeId, setActiveId] = useState<number | null>(null);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (!Object.keys(parsed).length) {
      const link = queryString.stringify({ page: 1 });
      navigate(`${location.pathname}?${link}`);
    }

    if (Object.keys(parsed).length) {
      setQuery(parsed);
      setActiveId(Number(parsed.page));
    }
  }, [location]);

  useEffect(() => {
    const index = isMobile ? activeId : (query?.page as any);
    const result = index && text[index] ? text[index] : text['1'];
    setContent(result[lang]);
  }, [query, lang, activeId]);

  const buttonHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const button: HTMLDivElement = event.currentTarget;
    navigate(`${location.pathname}?page=${button.id}`);
  };

  const handleMobileItem = (id: number) => {
    if (id === activeId) return setActiveId(null);
    setActiveId(id);
  };

  return (
    <Layout
      titlePage={data.title}
      titlePageMobile={data.titleMobile}
      title={siteTitle.title}
      description={siteData.description}
      styleTitle={{ marginBottom: '3.47vw', borderBottom: 'none' }}
    >
      {({ lang }: { lang: 'en' | 'ru' }) => (
        <>
          <Container>
            <div>
              {buttons(lang).map((item) => {
                const active = query?.page as any;
                return (
                  <Formality active={+active === item.id} key={item.id}>
                    <Button id={item.id.toString()} onClick={buttonHandler}>
                      {item.label}
                    </Button>
                  </Formality>
                );
              })}
            </div>
            <Content>
              {buttons(lang).map((item) => {
                const active = query?.page as any;
                return (
                  <ActiveText active={+active === item.id} key={item.id}>
                    <div>{item.content}</div>
                  </ActiveText>
                );
              })}
            </Content>
          </Container>

          <ContainerMobile>
            <div>
              {buttons(lang).map((item) => {
                return (
                  <Formality active={activeId === item.id} key={item.id}>
                    <div>
                      <Button
                        id={item.id.toString()}
                        onClick={() => handleMobileItem(item.id)}
                      >
                        <WrapperText>{item.label}</WrapperText>
                        <WrapperIcon active={activeId === item.id}>
                          <VectorDown />
                        </WrapperIcon>
                      </Button>
                    </div>
                    {activeId === item.id && (
                      <ContentMobile>{content}</ContentMobile>
                    )}
                  </Formality>
                );
              })}
            </div>
          </ContainerMobile>
        </>
      )}
    </Layout>
  );
};

export default Formalities;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: ${({ theme }) => theme.table_smallest}) {
    padding-right: max(1.4vw, env(safe-area-inset-left));
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: none;
  }
`;

const ContainerMobile = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: grid;
    margin-bottom: 20vw;
    overflow: hidden;
  }
`;

const Formality = styled.div<{ active: boolean }>`
  background: ${({ active }) => (active ? '#baff49' : 'transparent')};
  border-bottom: 0.21vw solid #000000;
  &:nth-child(3) {
    margin-bottom: 5vw;
  }
  &:hover {
    background: #baff49;
  }
  @media screen and (max-width: ${({ theme }) => theme.table_smallest}) {
    padding-left: max(1.4vw, env(safe-area-inset-left));
  }
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    display: grid;
    border-bottom: 0.6vw solid #000000;
    background: transparent;
    padding: 6.25vw 3.13vw 0 3.13vw;
    &:nth-child(3) {
      margin-bottom: 0;
    }
    &:hover {
      background: transparent;
    }
  }
`;

const Button = styled.div`
  padding: 2.08vw 0 2.08vw 1.39vw;
  font-family: 'Atyp Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.53vw;
  line-height: 1.88vw;
  text-align: justify;
  text-transform: uppercase;
  color: #071013;
  cursor: pointer;
  border: 0;
  background: transparent;
  width: 100%;
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-size: 5.31vw;
    line-height: 7.81vw;
    display: flex;
    justify-content: space-between;
    padding: 0 0 6vw 0;
  }
`;

const ContentMobile = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    font-family: 'Atyp Display', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #000000;
    font-size: 4.38vw;
    line-height: 5.94vw;
    border-left: none;
    padding: 0 3.13vw;
    width: 94.06vw;
    max-height: 80vw;
    word-wrap: break-word;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
`;

const Content = styled.div`
  border-left: 0.21vw solid #000000;
  padding: 1.39vw 1.39vw 0 1.39vw;
  max-height: 28vw;
  width: 100%;
`;

const WrapperIcon = styled.div<{ active: boolean }>`
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    width: 4.38vw;
    height: 4.38vw;
    transform: ${({ active }) => (active ? 'rotate(180deg)' : 'rotate(0)')};
    transition: all 0.2s ease-out;
    & > svg {
      width: 100%;
      height: 100%;
    }
  }
`;

const WrapperText = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.mobile_smallest}) {
    width: 74.13vw;
  }
`;

const ActiveText = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  & > div {
    line-height: 1.6vw;
    font-family: 'Atyp Display', sans-serif;
    font-size: 1.11vw;
    color: #000000;
    max-height: 23vw;
    word-wrap: break-word;
    overflow-x: hidden;
    overflow-y: scroll;
    @media screen and (max-width: ${({ theme }) => theme.table_smallest}) {
      font-size: 1.4vw;
      line-height: 2vw;
      color: #000000;
      -webkit-overflow-scrolling: touch;
    }
  }
`;
